declare global {
  interface Window {
    _paq: any[];
  }
}

export const matomoScript = (trackPageView?: boolean) => {
    const siteId = Number(process.env.REACT_APP_SITE_ID);
    const siteUrl = process.env.REACT_APP_MATOMO_URL || '';
    const baseUrl = window.location.hostname;
    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('noscript');
    const img = document.createElement('img');
    img.src = `${siteUrl}matomo.php?idsite=${siteId}&amp;rec=1`;
    img.setAttribute('style', 'border:0');
    img.alt = '';
    script.appendChild(img);
    head.appendChild(script);
  
    const _paq = (window._paq = window._paq || []);
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    _paq.push(['setCookieDomain', `*.${baseUrl}`]);
    _paq.push(['setDomains', [`*.${baseUrl}`]]);
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    const u = siteUrl;
    _paq.push(['setTrackerUrl', u + 'matomo.php']);
    _paq.push(['setSiteId', siteId]);
    const d = document,
      g = d.createElement('script'),
      s = d.getElementsByTagName('script')[0];
    g.type = 'text/javascript';
    g.async = true;
    g.src = u + 'matomo.js';
    if (!trackPageView) {
      s.parentNode?.insertBefore(g, s);
    }
  };
  
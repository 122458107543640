import React, { useEffect, useState } from "react";
import { Routes, Route } from 'react-router-dom'
import SlimHeader from "../components/SlimHeader";
import SlimFooter from "../components/SlimFooter";
import SearchPage from "./SearchPage";
import SearchDetailsPage from "./SearchDetailsPage";
import HomePage from "./HomePage";
import FeedbackPage from "./FeedbackPage";
import Error from "../components/Error";
import SegnalaSentenzaPage from "./SegnalaSentenza";
import '../components/styles.css';
import InfoPage from "./InfoPage";
//import { useMatomo } from "@datapunt/matomo-tracker-react";

type RootProps = {
    isLogged: boolean
    user: {
        name: string,
        familyName: string,
        fiscalCode: string,
        authmod: string,
        dateofbirth: string,
        adult: boolean
    }
    envData: {
        logoutUrl: string
    },
    isInHome: boolean
}

const Root = (props: RootProps) => {

    /*const { trackPageView } = useMatomo()

    useEffect(() => {
        trackPageView({})
      }, [])*/


    const [cardsLength, setCardsLength] = useState<number>(0)

    const privacyTitle = 'Privacy'
    const privacyContent = `<p><b>INFORMATIVA SUL TRATTAMENTO DEI DATI PERSONALI DEGLI UTENTI DEL SITO "https://bancadatigiurisprudenza.giustiziatributaria.gov.it"</b></p>
    <p>Questa pagina fornisce le informazioni sul trattamento dei dati personali degli utenti che consultano il sito web "https://bancadatigiurisprudenza.giustiziatributaria.gov.it" (di seguito, il "Sito"), ai sensi dell'art. 13 del Regolamento (UE) 2016/679 (di seguito, il "GDPR").</p>
    <br/>
    <p><b>Titolare del trattamento dei dati personali</b></p>
    <p>Il Titolare del trattamento dei dati è il Dipartimento della Giustizia Tributaria del Ministero dell'Economia e delle Finanze con sede in Via dei Normanni, 5 - 00184 Roma.</p>
    <br/>
    <p><b>Responsabile per la protezione dei dati personali</b></p>
    <p>Il Responsabile della Protezione dei Dati (DPO) è raggiungibile al seguente indirizzo: <a href="mailto:responsabileprotezionedati@mef.gov.it">responsabileprotezionedati@mef.gov.it</a>.</p>
    <br/>
    <p><b>Tipi di dati trattati, finalità e base giuridica del trattamento</b></p>
    <p><b>Dati di navigazione</b></p>
    <p>I sistemi informatici e le procedure software preposte al funzionamento del Sito acquisiscono, nel corso del loro normale esercizio, alcuni dati personali inerenti alla navigazione web la cui trasmissione è implicita nell'uso dei protocolli di comunicazione di Internet.</p>
    <p>Si tratta di informazioni che non sono raccolte per essere associate a interessati identificati, ma che per loro stessa natura potrebbero, attraverso elaborazioni ed associazioni con dati detenuti da terzi, permettere di identificare gli utenti.</p>
    <p>In questa categoria di dati rientrano:</p>
    <ul>
        <li>gli indirizzi IP dei computer utilizzati dagli utenti che si connettono al Sito;</li>
        <li>gli indirizzi in notazione URI (<i>Uniform Resource Identifier</i>) delle risorse richieste;</li>
        <li>l'orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal server (buon fine, errore, ecc.);</li>
        <li>altri parametri relativi al sistema operativo e all'ambiente informatico dell'utente.</li>
    </ul>
    <p>Il trattamento dei dati dell’utente è quindi necessario per il perseguimento da parte del Titolare di legittimi interessi, ai sensi dell’art. 6, par. 1, lett. f) del GDPR, correlati a finalità di controllo del corretto funzionamento del Sito, all’elaborazione in forma anonima di informazioni statistiche sull’uso del Sito, nonché all’accertamento, all’esercizio e alla difesa giudiziaria di diritti.</p>
    <p>I dati in questione potrebbero essere, inoltre, utilizzati per l’adempimento di obblighi normativi, ai sensi dell’art. 6, par. 1, lett. c) del GDPR, connessi alla gestione del Sito e per dare seguito a richieste avanzate dalla competente autorità giudiziaria e dalla polizia giudiziaria, per l'accertamento di responsabilità in caso di eventuali reati informatici commessi ai danni del Sito.</p>
    <br/>
    <p><b>Dati comunicati dall'utente</b></p>
    <p>L'invio facoltativo, esplicito e volontario di messaggi agli indirizzi di contatto del Titolare indicati nel Sito comporta l'acquisizione dei dati di contatto del mittente necessari a rispondere alle richieste inoltrate, nonché di tutti i dati personali inclusi nelle comunicazioni, il cui trattamento è effettuato per consentire al Titolare l’erogazione di un servizio all’utente, ai sensi dell’art. 6, par. 1, lett. b) del GDPR. Il Dipartimento della Giustizia Tributaria invita gli utenti, nella formulazione di tali richieste, a non inviare altri dati personali che non siano strettamente necessari.</p>
    <br/>
    <p><b>Cookie e altri strumenti di tracciamento</b></p>
    <p>I cookie sono stringhe di testo di piccole dimensioni che i siti web visitati inviano al browser dell’utente dove vengono memorizzati per poi essere ritrasmessi ai medesimi siti web durante le visite successive. Nel corso della navigazione, l’utente può ricevere sul proprio browser anche cookie inviati da soggetti diversi dal titolare del sito visitato (c.d. “terze parti”).</p>
    <br/>
    <p><b>Cookie tecnici</b></p>
    <p>Questo Sito utilizza i seguenti cookie tecnici:</p>
    <ul>
        <li>cookie di navigazione o di sessione, i quali garantiscono la normale navigazione e fruizione del Sito attraverso la memorizzazione delle preferenze di navigazione dell’utente;</li>
    </ul>
    <p>
        Questi cookie sono installati direttamente dal Titolare e vengono utilizzati, ai sensi dell’art. 6, par. 1, lett. f) del GDPR, per agevolare la navigazione e, ai sensi dell’art. 6, par. 1, lett. b) del GDPR, per fornire i servizi richiesti dall’utente. Una volta memorizzati sul browser dell’utente, ferma la possibilità per l’utente di provvedere direttamente alla cancellazione, laddove lo desideri, nelle modalità indicate nella sezione “Come disabilitare e cancellare i cookie”.
    </p>
    <p>
        Il Sito utilizza, altresì, cookie analitici propri che raccolgono, in forma anonima (attraverso il mascheramento di porzioni dell’indirizzo IP dell’utente), informazioni statistiche e aggregate sulle modalità di navigazione degli utenti (ad es., numero di pagine visitate e accessi, tempo di permanenza nel sito). Tali cookie, utilizzati direttamente dal gestore del Sito ai sensi dell’art. 6, par. 1, lett. f) del GDPR, sono assimilati ai cookie tecnici e non necessitano del consenso dell’utente. Inoltre, i predetti cookie analitici, una volta memorizzati sul browser dell’utente, ferma la possibilità per l’utente di decidere di disabilitarne l’utilizzo sul proprio browser, in qualsiasi momento, nelle modalità indicate nella sezione <i>"Come disabilitare e cancellare i cookie"</i>.
    </p>
    <br/>
    <p><b>Come disabilitare e cancellare i cookie</b></p>
    <p>
        In alcuni browser è possibile impostare regole per gestire i cookie sito per sito, opzione che offre un controllo più preciso sulla privacy. È possibile controllare le preferenze nelle pagine seguenti, a seconda del browser.
    </p>
    <ul>
        <li>Microsoft Edge: <a href="https://support.microsoft.com/it-it/windows/eliminare-e-gestire-i-cookie-168dab11-0753-043d-7c16-ede5947fc64d">https://support.microsoft.com/it-it/windows/eliminare-e-gestire-i-cookie-168dab11-0753-043d-7c16-ede5947fc64d</a></li>
        <li>Apple Safari: <a href="http://www.apple.com/it/support/mac-apps/safari/">http://www.apple.com/it/support/mac-apps/safari/</a></li>
        <li>Google Chrome: <a href="http://support.google.com/chrome/bin/answer.py?hl=it&hlrm=en&answer=95647">http://support.google.com/chrome/bin/answer.py?hl=it&hlrm=en&answer=95647</a></li>
        <li>Mozilla Firefox: <a href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie">https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie</a></li>
        <li>Opera: <a href="http://help.opera.com/Windows/10.20/it/cookies.html">http://help.opera.com/Windows/10.20/it/cookies.html</a></li>
    </ul>
    <p>Per i browser diversi da quelli sopra elencati, consultare la relativa guida per individuare le modalità di gestione dei cookie.</p>
    <br/>
    <p><b>Periodo di conservazione dei dati personali</b></p>
    <p>I dati personali forniti dall’utente e/o raccolti dal Titolare attraverso l’utilizzo del Sito verranno conservati per il tempo strettamente necessario a fornire i servizi richiesti e a svolgere le correlate operazioni tecniche e di sicurezza, nonché, terminato quest’ultimo, per i tempi eventualmente stabiliti dalle norme vigenti a fini amministrativi ed eventualmente difensivi.</p>
    <p>Per quanto concerne specificamente i cookie, i tempi di conservazione sono indicati nei paragrafi precedenti.</p>
    <br/>
    <p><b>I diritti degli interessati</b></p>
    <p>L’interessato ha il diritto, in qualunque momento, di ottenere la conferma dell’esistenza o meno dei dati che lo riguardano. Ha inoltre il diritto di chiedere, nelle forme previste dall’ordinamento, la rettifica dei dati personali inesatti e l’integrazione di quelli incompleti e di esercitare ogni altro diritto ai sensi degli artt. 18-22 del GDPR, laddove applicabili.</p>
    <p>Qualora l’utente ritenga che il trattamento sia avvenuto in modo non conforme al GDPR, potrà inoltre rivolgersi all’Autorità di controllo, proponendo reclamo ai sensi dell’art. 77 del GDPR.</p>
    <p>Ulteriori informazioni sono reperibili sul sito web del Garante per la Protezione dei Dati Personali all’indirizzo <a href="https://www.garanteprivacy.it/">www.garanteprivacy.it</a></p>
    <br/>
    <p>Per assicurare la continua disponibilità dei propri servizi e migliorarne le modalità di fruizione, il Titolare si avvale di fornitori terzi nominati ai sensi dell’art. 28 GDPR che presentano garanzie sufficienti per mettere in atto misure tecniche e organizzative adeguando il livello di protezione al rischio del trattamento dei dati personali. Alcuni di questi fornitori potrebbero, nell’ambito di ordinarie attività di supporto e manutenzione, trasferire ed elaborare i dati personali in regioni esterne allo Spazio Economico Europeo sulla base di decisioni di adeguatezza della Commissione UE o di equivalenti garanzie adeguate in ottemperanza alla normativa vigente.</p>
    <p>Ultimo aggiornamento: giugno 2024</p>
    `
    const legalNoteTitle = 'Note legali'
    const legalNoteContent = `<p>Il presente Sito è gestito dal Dipartimento della Giustizia Tributaria del Ministero dell'Economia e delle Finanze (MEF) che si avvale del supporto tecnico della Direzione Sistema Informativo della Fiscalità del Dipartimento delle Finanze. Il Sito viene aggiornato secondo la disponibilità di nuovi contenuti, anche più volte nelle ventiquattrore. Ai contenuti del Sito si applicano le norme in materia di tutela dei dati personali, quelle sul copyright e la clausola di esclusione della responsabilità. Tutti i contenuti del sito (codice di script, grafica, testi, tabelle, immagini, suoni, e ogni altra informazione disponibile in qualunque forma), ad esclusione di quanto di proprietà di terzi, si intendono forniti con licenza Creative Commons - Attribuzione Non Commerciale (CC BY-NC 3.0 IT).</p>
<p>Consulta il <a
              href="https://creativecommons.org/licenses/by-nc/3.0/it/legalcode"
              target="_blank"
              class="external-link"
            >
              testo integrale della licenza
              <span class="visually-hidden">
                : apre una nuova finestra
              </span>
            </a>.</p>
<br />
<p><b>Clausola di esclusione della responsabilità</b></p>
<p>In nessuna circostanza, ivi compresa, senza alcuna limitazione, la negligenza, il Ministero dell'Economia e delle Finanze, i suoi fornitori o i collaboratori potranno essere ritenuti responsabili per qualsiasi danno diretto, indiretto, incidentale, consequenziale, legato all'uso del presente sito web o di altri siti web ad esso collegati da un link ipertestuale, ivi compresi senza alcuna limitazione, i danni quali la perdita di fatturato, l'interruzione di attività aziendale o professionale, la perdita di programmi o altro tipo di dati ubicati sul sistema informatico dell'utente o altro sistema, e ciò anche qualora il Dipartimento della giustizia tributaria fosse stato espressamente messo al corrente della possibilità del verificarsi di tali danni. Il Dipartimento della giustizia tributaria non è in alcun modo responsabile dei contenuti e dei servizi posti in siti web distinti, raggiungibili dal Sito, sui quali non è possibile alcun controllo. Inoltre l'esistenza di un collegamento ipertestuale (link) verso un altro sito web non comporta l'approvazione o un'accettazione di responsabilità, neanche parziale e/o indiretta, da parte del Dipartimento della giustizia tributaria circa il contenuto o l'utilizzo del sito. Il Dipartimento della giustizia tributaria, inoltre, non è responsabile in alcun modo della qualità dei servizi di connettività di rete utilizzati dagli utenti del Sito per fruire dei servizi presenti. La presente clausola di esclusione della responsabilità non ha lo scopo di eludere il rispetto dei requisiti prescritti dalla normativa vigente, né di escludere la responsabilità per i casi nei quali essa non possa essere esclusa ai sensi della normativa applicabile.</p>`

    return (
        <>
            <SlimHeader isInHome={props.isInHome} envData={props.envData} isLogged={props.isLogged} user={props.user} />
            <Routes>
                <Route path="/" element={<HomePage setCardsLength={setCardsLength} />} />
                <Route path="ricerca" element={<SearchPage envData={props.envData} />} />
                <Route path="ricerca/dettaglio" element={<SearchDetailsPage />} />
                <Route path="feedback" element={<FeedbackPage />} />
                <Route path="*" element={<Error message="la pagina richiesta non esiste!" styleContainer={true} />} />
                <Route path="ricerca/dettaglio/segnala-sentenza" element={<SegnalaSentenzaPage />} />
                <Route path="privacy" element={<InfoPage title={privacyTitle} content={privacyContent} />} />
                <Route path="note-legali" element={<InfoPage title={legalNoteTitle} content={legalNoteContent} />} />
            </Routes>
            <SlimFooter isInHome={props.isInHome} cardsLength={cardsLength}/>
        </>
    );
}

export default Root;
import React from "react";
import './styles.css';

type spinnerProps = {
  id?: string
}

const Spinner = (props: spinnerProps) => {

  return (
    <div className="spinner-container">
      <div className="spinner" id={props.id ? props.id : 'spinner'} role="status">
        <div className="spinner-border text-primary"></div>
        <span className="visually-hidden">Caricamento in corso...</span>
      </div>
    </div>
  );
}

export default Spinner;

import React, { useEffect, useState } from "react";
import SearchBar from "./SearchBar";
import AdvancedResearch from "./AdvancedResearch";
import { Form, Button, Row, Col } from "react-bootstrap";
import SearchItem from "./SearchItem";
import { CONSTANTS } from "../utils/constants";
import { useAppDispatch, useAppSelector } from "../store/store";
import { setAnnoSentenza, setNumeroSentenza, setRequestAfterRefresh } from "../store/search/reducer";
import './styles.css';
import Error from "./Error";
import Modal from "./Modal";

type SearchFormProps = {
  filterList: Array<any>,
  yearsList: Array<any>,
  autorityGradeList: Array<any>,
  autorityList: Array<any>,
  disputeList: Array<any>,
  judgementTypeList: Array<any>,
  judgementOutcomeList: Array<any>,
  matterList: Array<any>,
  classificationList: Array<any>,
  showResultHandler: (val: boolean) => void,
  isErrorText?: boolean,
  errorMessageText?: string,
  isErrorNumero?: boolean,
  errorMessageNumero?: string,
  showAlert?: boolean,
  showAlertErrorBE?: boolean,
  showLoader?: boolean;
  speseGiudizioList: Array<any>
  sentenzaImpugnataList: Array<any>
  invalidDateError?: boolean;
  invalidDateErrorMessage?: string;
  unmountDataInizio: boolean;
  unmountDataFine: boolean
}

const SearchForm = (props: SearchFormProps) => {
  const { request } = useAppSelector((state) => state.searchResults);
  const requestFromLocal = JSON.parse(localStorage.getItem('request') as string)
  const [disabledSearch, setDisabledSearch] = useState(false)

  const dispatch = useAppDispatch();

  const modalSearchSentenzeTitle = `Criteri di ricerca`;
  const modalSearchSentenzeContent = `
  I criteri di ricerca standard utilizzabili sono:
  <ul>
    <li><b>ricerca testuale</b>, inserendo una o più parole chiave nel campo <b><i>“Parole da ricercare”</i></b> e selezionando la 
    modalità di ricerca nel corrispondente menu a tendina. 
    </li>
    <ul>
      <li>Tutte le parole (il sistema ricerca in tutto il testo della sentenza le parole indicate, indipendentemente dalla posizione e dall'ordine)</li>
      <li>Almeno una parola </li>
      <li>Frase esatta </li>
      <li>Parole adiacenti (il sistema ricerca i documenti che contengano i termini inseriti vicini tra loro, in un intorno di massimo 5 parole)</li>
      <li>Con operatori logici&nbsp;- consente di effettuare la ricerca con gli operatori logici AND, OR, NOT secondo le seguenti regole:
        <ul>
          <li>operatori <strong>AND </strong>e <strong>OR</strong>:<br>
            parola1 <strong>AND</strong> parola2 – ricerca i documenti che contengono entrambe le parole;<br>
            parola1 <strong>OR</strong> parola2 – ricerca i documenti che contengono almeno una delle parole;<br>
            si noti che vengono ricercati anche i singolari, i plurali e le forme flesse allargate delle parole inserite: 
            per limitare la ricerca ai termini esatti le parole vanno indicate tra virgolette, nell'esempio "parola1" 
            <strong>AND</strong> "parola2" , "parola1" <strong>OR</strong> "parola2"
          </li>
        </ul>
        <ul>
          <li>operatore <strong>NOT</strong>:<br>
            <strong>NOT</strong> parola1 - ricerca i documenti che non contengono la parola indicata; 
            a differenza degli altri operatori logici, la parola indicata viene ricercata solo nella forma esatta in cui è stata inserita.
          </li>
        </ul>
        Queste stesse regole vengono applicate anche quando si imposta la ricerca con frasi.<br>
        Nella costruzione delle diverse combinazioni, possono essere usati solo alcuni caratteri speciali - parentesi tonde () e doppi apici " "
      </li>
    </ul>
    <li><b>Anno di deposito</b> della sentenza e <b>numero della sentenza</b>
     inserendo almeno una parola chiave o selezionando un anno.</li>
    <br>
    <li>Nella sezione <b>Ricerca Avanzata</b> sono presenti ulteriori filtri che si possono utilizzare per la ricerca:</li>
      <ul>
        <li><b>Data deposito:</b> la data in cui la sentenza è stata depositata, nel formato gg/mm/aaaa</li>
        <li><b>Grado autorità emittente:</b> grado di giudizio della Corte di Giustizia Tributaria. Le scelte possibili sono “CGT primo grado/Provinciale”, 
        “CGT secondo grado/Regionale” o “intera regione”, in quest’ultimo caso, selezionando una regione dal menu a tendina "Autorità emittente”, verranno filtrate tutte le Corti di primo e di secondo grado presenti nella regione indicata</li>
        <li><b>Autorità emittente:</b> Corte di Giustizia Tributaria presso la quale è stata depositata la sentenza. È possibile selezionare più di un valore selezionandoli mediante la combinazione del "Tasto sinistro" del mouse + "Tasto Ctrl" della tastiera.</li>
        <li><b>Sentenza impugnata:</b> SI/NO; selezionabile solo nel caso in cui sia stata scelta come grado autorità emittente “CGT primo grado/Provinciale”; consente di ricercare le sentenze di primo grado per le quali risulti o non risulti presentato appello</li>
        <li><b>Valore controversia:</b> il valore della controversia di una sentenza è calcolato come la somma di tutti gli importi dei singoli ricorsi al netto di interessi e sanzioni. È possibile selezionare i seguenti range di valori:</li>
        <ul>
          <li>Fino a 5.000 euro</li>
          <li>da 5.000,01 a 20.000 euro</li>
          <li>Da 20.000,01 euro a 1.000.000 di euro</li>
          <li>Oltre 1.000.000 di euro</li>
        </ul>
        <li><b>Tipo giudizio:</b> collegiale o monocratico</li>
        <li><b>Esito giudizio:</b> Conciliazione, Condono e altri esiti, Esito non definitorio su pronunciamento definitorio, Favorevole al contribuente, Favorevole all’ufficio, Giudizio intermedio, Reclamo respinto</li>
        <li><b>Materia:</b> viene presentata una lista delle 'materie fiscali' che sono state indicate in fase di deposito della sentenza digitale</li>
        <li><b>Spese Giudizio:</b> Compensate, A carico del contribuente, A carico dell'ufficio; nelle spese di giudizio compensate vengono ricomprese anche le spese non definite e non liquidate
      </ul>
    </li>
  </ul>
  `;

  const judgementNumberCallback = (val: string) => {
    dispatch(setNumeroSentenza(val.trim()))
  }

  const judgementYearCallback = (val: number) => {
    dispatch(setAnnoSentenza(val))
  }


  const onClickSearch = () => {
    props.showResultHandler(true)
  }

  const onClickClear = () => {
    setTimeout(() => {
      props.showResultHandler(false)
    }, 250);
  }

  useEffect(() => {
    if (requestFromLocal)
      dispatch(setRequestAfterRefresh(requestFromLocal))
  }, [])

  return (
    <div className={`container py-3 ${props.showLoader ? 'opacity-class' : 'opacity-class-unset'} `}>
      {
        props.showAlert ?
          <div id="alert-danger" className="alert alert-danger" role="alert">
            Per effettuare una ricerca è necessario inserire almeno una parola o selezionare un anno
          </div> :
          props.showAlertErrorBE ?
            <Error message="si è verificato un errore!" /> :
            props.invalidDateError && props.invalidDateErrorMessage !== '' ?
              <Error message={props.invalidDateErrorMessage ? props.invalidDateErrorMessage : ''} noBackToHome={true} />
              : null
      }
      <div role='region' aria-label="form ricerca sentenze segnalate">
        <Form id="search">
          <Form.Group className="mb-3">
            <SearchBar
              required={true}
              title='Parole da ricercare'
              disabled={props.showLoader}
              initialValueCampoRicerca={request?.filter?.campoRicerca}
              initialValueModRicerca={request?.filter?.modRicercaDescrizione}
              isError={props.isErrorText}
              errorMessage={props.errorMessageText}
              filterList={props.filterList}
            />
            <Row>
              <Col className="col-md-3">
                <SearchItem
                  disabled={props.showLoader}
                  initialValue={request?.filter?.numeroSentenza}
                  value=""
                  maxlength={10}
                  isError={props.isErrorNumero}
                  errorMessage={props.errorMessageNumero}
                  id="Form.ControlInput1"
                  title="Numero di sentenza"
                  placeholder="Numero di sentenza"
                  type="control"
                  callback={judgementNumberCallback}
                />
              </Col>
              <Col className="col-md-3">
                <SearchItem
                  disabled={props.showLoader}
                  initialValue={request?.filter?.annoSentenza}
                  required={true}
                  value="codice"
                  id="Form.ControlInput2"
                  title="Anno"
                  placeholder="Seleziona"
                  type="select"
                  callback={judgementYearCallback}
                  list={props.yearsList}
                />
              </Col>
            </Row>
            <div className="d-flex justify-content-between align-items-center">

              <p className="mt-3">*Indicare almeno una parola da ricercare o selezionare un anno.</p>
              <i className="bi bi-info-circle mx-2 info-cursor fs-3" data-bs-toggle="modal" data-bs-target="#search-modal" title="Informazioni sui filtri di ricerca"></i>
            </div>
            <AdvancedResearch
              autorityGradeList={props.autorityGradeList}
              autorityList={props.autorityList}
              disputeList={props.disputeList}
              judgementTypeList={props.judgementTypeList}
              judgementOutcomeList={props.judgementOutcomeList}
              matterList={props.matterList}
              classificationList={props.classificationList}
              speseGiudizioList={props.speseGiudizioList}
              showLoader={props.showLoader}
              sentenzaImpugnataList={props.sentenzaImpugnataList}
              unmountDataInizio={props.unmountDataInizio}
              unmountDataFine={props.unmountDataFine}
            />
            <Row className="align-items-center">
              <Col md="auto">
                <Button
                  onClick={() => onClickSearch()}
                  disabled={disabledSearch || props.showLoader}
                >
                  {CONSTANTS.SEARCH_PAGE.searchButtonLabel}
                </Button>
              </Col>
              <Col>
                <Button
                  variant="link"
                  onClick={() => onClickClear()}
                  disabled={props.showLoader}
                >
                  {CONSTANTS.SEARCH_PAGE.clearButtonLabel}
                </Button>
              </Col>
            </Row>
          </Form.Group>
        </Form>
      </div>
      <hr />
      <Modal title={modalSearchSentenzeTitle} content={modalSearchSentenzeContent} id="search-modal"></Modal>
    </div>
  );
}

export default SearchForm;

import { createSlice } from "@reduxjs/toolkit";

interface ResultsState {
  response: [
    {
      idFilenet: string;
      numero: string;
      anno: string;
      autoritaEmittente: string;
      dataEmissione: string;
      valoreControversia: string;
      tipoGiudizio: string;
      esito: string;
      materia: string;
      materiaClassificazione: string;
      flagSpeseGiudizio: string | null;
      importoSpeseGiudizioParte: number | string | null;
      importoSpeseGiudizioUfficio: number | string | null;
      abstractSentenza: string | null;
      statoGiudizio: string;
      gradoCommissione: string;
    }
  ];
}

const initialState: ResultsState = {
  response: [
    {
      idFilenet: "",
      numero: "",
      anno: "",
      autoritaEmittente: "",
      dataEmissione: "",
      valoreControversia: "",
      tipoGiudizio: "",
      esito: "",
      materia: "",
      materiaClassificazione: "",
      flagSpeseGiudizio: null,
      importoSpeseGiudizioParte: null,
      importoSpeseGiudizioUfficio: null,
      abstractSentenza: null,
      statoGiudizio: "",
      gradoCommissione: "",
    },
  ],
};

export const resultsSlice = createSlice({
  name: "search response results",
  initialState,
  reducers: {
    setResults(state, action) {
      state.response = action.payload;
    },
  },
});

export const { setResults } = resultsSlice.actions;
export default resultsSlice.reducer;

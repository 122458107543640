import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import './styles.css';
import { Link } from "react-router-dom";

type SlimFooterProps = {
  isInHome: boolean,
  cardsLength: number
}

const SlimFooter = (props: SlimFooterProps) => {

  return (
    <footer>
      <div className={`footer-dark footer-color pt-4 ${props.isInHome ? `custom-padding-footer-${props.cardsLength}-cards` : ''}`}>
        <div className="container custom-padding-images-footer py-xs-4 py-sm-4 py-md-4">
          <Row className="gx-variable align-items-center">
            <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
              <Image
                className="logo-mef-footer"
                src={process.env.PUBLIC_URL + "/img/DGT versione web-negativo.svg"}
                alt="Logo del Mef"
              ></Image>
            </div>
            <div className="custom-margin-footer-images col-12 col-sm-12 col-md-6 col-lg-auto col-xl-auto col-xxl-auto">
              <Image
                className="logo-partner-footer"
                src={process.env.PUBLIC_URL + "/img/EU.svg"}
                alt="Logo Unione Europea"
              ></Image>
            </div>
            <div className="custom-margin-footer-images col-12 col-sm-12 col-md-6 col-lg-auto col-xl-auto col-xxl-auto">
              <Image
                className="logo-partner-footer my-sm-4 my-md-4"
                src={process.env.PUBLIC_URL + "/img/PCM.svg"}
                alt="Logo Agenzia per la Coesione Territoriale"
              ></Image>
            </div>
            <div className="custom-margin-footer-images col-12 col-sm-12 col-md-6 col-lg-2 col-xl-auto col-xxl-auto">
              <Image
                className="logo-partner-footer my-sm-4 my-md-4"
                src={process.env.PUBLIC_URL + "/img/PON.svg"}
                alt="Logo PON"
              ></Image>
            </div>
          </Row>
          <hr />
          <Row  >
            <Col className="my-2">
              <Row>
                <span><strong>Direzione I </strong>- Normativa, affari giuridici e magistrati. </span>
              </Row>
              <Row>
                <span><strong>Ufficio V </strong>- Coordinamento e gestione della banca dati della giurisprudenza tributaria di merito; studi e approfondimenti della giurisprudenza tributaria. </ span>
              </Row>
            </Col>
            <Col className="my-2">
              <span>Per segnalazioni sulla banca dati della giurisprudenza tributaria scrivere a <a href="mailto:bancadatigt@mef.gov.it" className="link-white fw-semibold">bancadatigt@mef.gov.it</a></span>
            </Col>
            <Col className="my-2">
              <a
                href="https://assistenza.dgt.mef.gov.it/GiustiziaTributaria/s/articolo-detail?urlName=Consultare-Banca-Dati-Giurisprudenza-Tributaria-16867"
                className="link-white external-link"
                target="_blank"
              >
                <strong>
                  INFO E ASSISTENZA 
                  <span className="visually-hidden">
                    : apre una nuova finestra
                  </span>
                </strong>
              </a>
              <br />
              <span>Assistenza ai servizi online del Dipartimento della Giustizia Tributaria</span>
            </Col>
          </Row>
          <Row className="gx-variable">
            <Col className="col-12">
              <hr />
              <h2 className="visually-hidden">Altre informazioni</h2>
              <ul className="list-inline d-inline-block me-3">
                <li className="list-inline-item">
                  <a
                    href="https://form.agid.gov.it/view/d230e330-782f-11ef-8e4f-41792f460108"
                    className="link-white external-link"
                    target="_blank"
                  >
                    Dichiarazione di accessibilità
                    <span className="visually-hidden">
                      : apre una nuova finestra
                    </span>
                  </a>
                </li>
                <li className="list-inline-item">
                  <Link to='privacy' className="link-white">Privacy</Link>
                </li>
                <li className="list-inline-item">
                  <Link to='note-legali' className="link-white">Note legali</Link>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      </div>
    </footer>
  );
}

export default SlimFooter;

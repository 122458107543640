import React from 'react'

type InfoCardProps = {
  title: string,
  content: string
}

const InfoCard = (props: InfoCardProps) => {
  return (
    <div className="card info-card">
      <div dangerouslySetInnerHTML={{ __html: props.title }} />
      <div className="card-body">
        <div dangerouslySetInnerHTML={{ __html: props.content }} />
      </div>
    </div>
  )
}

export default InfoCard
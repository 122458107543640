import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import UserDropdown from "./UserDropdown";
import './styles.css';
import { Link } from "react-router-dom";

type HeaderProps = {
  isLogged: boolean
  user: {
    name: string,
    familyName: string,
    fiscalCode: string,
    authmod: string,
    dateofbirth: string,
    adult: boolean
  }
  envData: {
    logoutUrl: string
  }
}


const AppHeader = (props: HeaderProps) => {

  return (
    <div>
      <div className="container py-1 py-md-2">
        <Row className="gx-variable align-items-center justify-content-between">
          <Col className="col-md-8 col-sm-12">
            <Row>
              <Col className="col-2 p-3">
                <Link to='/'>
                  <Image
                    className="logo-dgt"
                    src={process.env.PUBLIC_URL + "/img/Banca_dati_giurisprudenza_tributaria.svg"}
                    alt="Logo Banca Dati Giurisprudenza Tributaria"
                    title="Torna alla home"
                  ></Image>
                </Link>
              </Col>
            </Row>
          </Col>
          <Col className="col-md-2 col-sm-6 text-end">
            <a
              href="https://assistenza.dgt.mef.gov.it/GiustiziaTributaria/s/articolo-detail?urlName=Consultare-Banca-Dati-Giurisprudenza-Tributaria-16867"
              className="info-header-title"
              target="_blank"
            >
              <b>Info e assistenza</b>
              <span className="visually-hidden">
                : apre una nuova finestra
              </span>
            </a>
          </Col>
          {
            props.isLogged ?
              <Col className="col-md-2 col-sm-6 text-end">
                <UserDropdown envData={props.envData} label={props.user?.fiscalCode ? props.user.fiscalCode : "-"} />
              </Col>
              : null
          }
        </Row>
      </div>
    </div>
  );
}

export default AppHeader;

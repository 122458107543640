import React from 'react';
import { Modal, Button } from 'react-bootstrap';

type ModalProps = {
  title: string,
  content: string,
  show: boolean,
  onReload: () => void,
};

const ModalSessionExpired = (props: ModalProps) => {
  return (
    <Modal show={props.show} backdrop="static" keyboard={false} centered>
      <Modal.Header>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body dangerouslySetInnerHTML={{ __html: props.content }} />
      <Modal.Footer>
        <Button variant="primary" onClick={props.onReload}>
          Ricarica
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalSessionExpired;

  


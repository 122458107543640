import { configureStore } from "@reduxjs/toolkit";
import searchResultsReducer, { searchResultsSlice } from "./search/reducer";
import searchDetailsReducer from "./searchDetails/reducer";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import segnalaSentenzaReducer from "./segnalaSentenza/reducer";
import { configSlice } from "./config/reducer";
import { resultsSlice } from "./searchResults/reducer";

const store = configureStore({
  reducer: {
    searchResults: searchResultsReducer,
    searchDetails: searchDetailsReducer,
    setSearchBarFilter: searchResultsSlice.reducer,
    segnalaSentenza: segnalaSentenzaReducer,
    config: configSlice.reducer,
    searchResponse: resultsSlice.reducer
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

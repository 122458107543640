import { getPaginationItems } from '../lib/pagination';
import PageLink from './PageLink';
import './styles.css';

export type Props = {
    currentPage: number;
    lastPage: number;
    maxLength: number;
    setCurrentPage: (page: number) => void;
    setClickedPageButton: (clicked: boolean) => void;
};

export default function Pagination({
    currentPage,
    lastPage,
    maxLength,
    setCurrentPage,
    setClickedPageButton
}: Props) {
    const pageNums = getPaginationItems(currentPage, lastPage, maxLength);

    return (
        <nav className="pagination" aria-label="Pagination">
            <PageLink
                disabled={currentPage === 1}
                onClick={() => {setCurrentPage(currentPage - 1); setClickedPageButton(true)}}
                className='info-cursor'
            >
                {"<"}
            </PageLink>
            {pageNums.map((pageNum, idx) => (
                <PageLink
                    key={idx}
                    active={currentPage === pageNum}
                    disabled={isNaN(pageNum)}
                    onClick={() => {
                        setCurrentPage(pageNum);
                        setClickedPageButton(true)
                    }}
                    className='info-cursor'
                >
                    {!isNaN(pageNum) ? pageNum : '...'}
                </PageLink>
            ))}
            <PageLink
                disabled={currentPage === lastPage}
                onClick={() => {setCurrentPage(currentPage + 1); setClickedPageButton(true)}}
                className='info-cursor'
            >
                {">"}
            </PageLink>
        </nav>
    );
}
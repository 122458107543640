import { createSlice } from "@reduxjs/toolkit";
import { SearchRequestModel } from "./model";

interface SearchResultsState {
  loading: boolean;
  requestSegnalazione: SearchRequestModel;
}

const initialState: SearchResultsState = {
  loading: false,
  requestSegnalazione: {
    motivo: "",
    testo: "",
    email: "",
    code: "",
  },
};

export const segnalaSentenzaSlice = createSlice({
  name: "search results",
  initialState,
  reducers: {
    setMotivo(state, action) {
      state.requestSegnalazione.motivo = action.payload;
    },
    setTesto(state, action) {
      state.requestSegnalazione.testo = action.payload;
    },
    setEmail(state, action) {
      state.requestSegnalazione.email = action.payload;
    },
    setCode(state, action) {
      state.requestSegnalazione.code = action.payload;
    },
    clearSegnalazione(state, action) {
      state.requestSegnalazione = initialState.requestSegnalazione;
    },
  },
});

export const { setMotivo, setTesto, setEmail, setCode, clearSegnalazione } =
  segnalaSentenzaSlice.actions;
export default segnalaSentenzaSlice.reducer;

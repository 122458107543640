import React, { useEffect, useState, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CustomTable from "../components/CustomTable";
import SearchForm from "../components/SearchForm";
import { clearRequest, setEmissioneFine, setEmissioneInizio, setPaginationDetails, setRequestAfterRefresh, setReturnFromDetails } from "../store/search/reducer";
import { useAppDispatch, useAppSelector } from "../store/store";
import { CONSTANTS, INVALID_DATE } from "../utils/constants";
import './search.css'
import { Link } from "react-router-dom";
import '../components/styles.css';
import Error from "../components/Error";
import Spinner from "../components/Spinner";
import { setResults } from "../store/searchResults/reducer";
import { useLocation } from "react-router-dom";
import ModalSessionExpired from "../components/ModalSessionExpired";
import Pagination from "../components/Pagination";

interface SearchProps {
    envData: {
        logoutUrl: string
    }
}

const Search = (props: SearchProps) => {

    const [ready, setReady] = useState<Boolean>(false)
    const [filterAll, setFilterAll] = useState<Array<{}>>([])
    const [filterYears, setFilterYears] = useState<Array<{}>>([])
    const [filterGrado, setFilterGrado] = useState<Array<{
        id: number,
        codice: string,
        descrizione: string,
        grado: string,
        ordinameno: number
    }>>([])
    const [filterType, setFilterType] = useState<Array<
        {
            codice: ""
            descrizione: ""
            grado: ""
            id: 0
        }
    >>([])
    const [loadedData, setLoadedData] = useState(false)
    const [message, setMessage] = useState(CONSTANTS.SEARCH_PAGE.emptySearchMessageFirstTime)
    const [filterAutoritaAll, setFilterAutoritaAll] = useState<Array<{
        codice: ""
        descrizione: ""
        grado: ""
        regione: ""
    }>>([])
    const [filterEsitoAll, setFilterEsitoAll] = useState<Array<{
        codiceEsito: ""
        codiceMacroesito: ""
        descrizioneEsito: ""
        descrizioneMacroesito: ""
    }>>([])
    const [filterAutoritaById, setFilterAutoritaById] = useState<Array<{}>>([])
    const [filterControversia, setFilterControversia] = useState<Array<{}>>([])
    const [filterEsito, setFilterEsito] = useState<Array<{}>>([])
    const [filterMateria, setFilterMateria] = useState<Array<{}>>([])
    const [filterMateriaClass, setFilterMateriaClass] = useState<Array<{
        codiceClassificazione: ""
        codiceMateria: ""
        descrizioneMateria: ""
        descrizioneClassificazione: ""
    }>>([])
    const [filterClassificazione, setFilterClassificazione] = useState<Array<{}>>([])
    const [filterSpeseGiudizio, setFilterSpeseGiudizio] = useState<Array<{
        id: number,
        codice: string,
        descrizione: string,
        grado: string,
        ordinameno: number
    }>>([])
    const [filterSentence, setFilterSentence] = useState<Array<{}>>([])
    const [resultTableData, setResultTableData] = useState<Array<{}>>([])
    const [showResult, setShowResult] = useState<Boolean>(false)
    const [isErrorText, setIsErrorText] = useState(false)
    const [isErrorNumero, setIsErrorNumero] = useState(false)
    const isErrorNumeroRef = useRef(false)
    const isErrorTextRef = useRef(false)
    const [errorMessageText, setErrorMessageText] = useState("")
    const [errorMessageNumero, setErrorMessageNumero] = useState("")
    const [showAlert, setShowAlert] = useState(false)
    const [showAlertErrorBE, setShowAlertErrorBE] = useState(false)
    const { request } = useAppSelector((state) => state.searchResults);

    const { returnFromDetails } = useAppSelector((state) => state.searchResults);
    const { paginationDetails } = useAppSelector((state) => state.searchResults);
    const [serverError, setServerError] = useState(false)
    const [showErrorMaxResultLength, setShowErrorMaxResultLength] = useState(false)
    const [responseLength, setResponseLength] = useState(0)
    const [responseLimit, setResponseLimit] = useState(0);
    const [invalidDateError, setInvalidDateError] = useState(false);
    const [invalidDateErrorMessage, setInvalidDateErrorMessage] = useState('');
    const [unmountDataInizio, setUnmountDataInizio] = useState(false)
    const [unmountDataFine, setUnmountDataFine] = useState(false)
    const dispatch = useAppDispatch();

    const location = useLocation();
    const [showModal, setShowModal] = useState(false)

    const isErrorDateRef = useRef(false);

    const [clickedPageButton, setClickedPageButton] = useState(false);

    const changePage = (pageNumber: number) => {
        searchRequest(pageNumber, 10);
    }
    const [currentPage, setCurrentPage] = useState<number>(1);

    const apiPath = process.env.REACT_APP_SERVER_HOST_PUBLIC

    function numberWithCommas(number: number) {
        let parseNumber = number?.toString();
        var pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(parseNumber))
            parseNumber = parseNumber?.replace(pattern, "$1.$2");
        return parseNumber;
    }

    //cambio pagina
    useEffect(() => {
        if (clickedPageButton && !returnFromDetails) {
            setLoadedData(false)
            changePage(currentPage)

            document.title = "Elenco risultati ricerca Giurisprudenza - " + CONSTANTS.ROOT_PAGE.headerTitle;
            try {
                if (window._paq) {
                    window._paq.push(['setCustomUrl', String(window.location)]);
                    window._paq.push(['setDocumentTitle', window.document.title]);
                    window._paq.push(['setGenerationTimeMs', 0]);
                    window._paq.push(['trackPageView']);
                }
            } catch (e) {
                console.error('trackPageView error', e);
            }
        }
    }, [currentPage])

    //primo caricamento
    useEffect(() => {
        if (!showResult && !returnFromDetails) {
            document.title = "Ricerca Giurisprudenza - " + CONSTANTS.ROOT_PAGE.headerTitle;
            try {
                if (window._paq) {
                    window._paq.push(['setCustomUrl', String(window.location)]);
                    window._paq.push(['setDocumentTitle', window.document.title]);
                    window._paq.push(['setGenerationTimeMs', 0]);
                    window._paq.push(['trackPageView']);
                }
            } catch (e) {
                console.error('trackPageView error', e);
            }
        }
    }, []);

    //ricerca solo cambio titolo, il tracciamento della ricerca avviene nell'effect del cambio pagina
    useEffect(() => {
        if (ready && !serverError && showResult) {
            document.title = "Elenco risultati ricerca Giurisprudenza - " + CONSTANTS.ROOT_PAGE.headerTitle;
        } else {
            document.title = "Ricerca Giurisprudenza - " + CONSTANTS.ROOT_PAGE.headerTitle;
        }
    }, [showResult]);

    useEffect(() => {
        if (ready && !serverError && showResult) {
            document.title = "Elenco risultati ricerca Giurisprudenza - " + CONSTANTS.ROOT_PAGE.headerTitle;
            try {
                if (window._paq) {
                    window._paq.push(['setCustomUrl', String(window.location)]);
                    window._paq.push(['setDocumentTitle', window.document.title]);
                    window._paq.push(['setGenerationTimeMs', 0]);
                    window._paq.push(['trackPageView']);
                }
            } catch (e) {
                console.error('trackPageView error', e);
            }
        } else {
            document.title = "Ricerca Giurisprudenza - " + CONSTANTS.ROOT_PAGE.headerTitle;
        }
    }, [showResult]);

    useEffect(() => {
        //Recupero parametri di ricerca dal local-storage
        const requestFromLocal = JSON.parse(localStorage.getItem("request") as string)
        if (returnFromDetails || requestFromLocal) {
            //Recupero risultati di ricerca dal local-storage
            const responseFromLocal = JSON.parse(localStorage.getItem("response") as string)
            if (responseFromLocal) {
                setResultTableData(responseFromLocal)
            }
            //Recupero dettaglio paginazione dal local-storage e lo setto in redux per non cambiare la logica gia funzionante
            const paginationFromLocal = JSON.parse(localStorage.getItem("paginationDetails") as string)
            if (paginationFromLocal) {
                dispatch(setPaginationDetails({
                    totalElements: paginationFromLocal.totalElements,
                    pageNumber: paginationFromLocal.pageNumber,
                    totalPages: paginationFromLocal.totalPages
                }))
                setCurrentPage(paginationFromLocal.pageNumber)
                setLoadedData(true)
                setReady(true)
                setShowResult(true)
                dispatch(setReturnFromDetails(false))
                dispatch(setRequestAfterRefresh(requestFromLocal))
                setTimeout(() => {
                    const table = document.getElementById('table-title')
                    table?.scrollIntoView()
                }, 100);
            }
        }
        else {
            setTimeout(() => {
                window.scrollTo({
                    top: 0,
                });
            }, 100);
        }
        fetch(`${apiPath}/v1/filter/filter/{desc}?desc=RICERCA_TESTUALE`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => res.json())
            .then((response) => {
                setServerError(false)
                setFilterAll(response)
                fetch(`${apiPath}/v1/filter/filter/{desc}?desc=RICERCA_ANNO`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                    .then(res => res.json())
                    .then((response) => {
                        setServerError(false)
                        setFilterYears(response)
                        fetch(`${apiPath}/v1/filter/filter/{desc}?desc=RICERCA_GRADO_AUTORITA`, {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                            }
                        })
                            .then(res => res.json())
                            .then((response) => {
                                setServerError(false)
                                setFilterGrado(response)
                                fetch(`${apiPath}/v1/filter/filter/{desc}?desc=RICERCA_TIPO_GIUDIZIO`, {
                                    method: 'GET',
                                    headers: {
                                        'Content-Type': 'application/json',
                                    }
                                })
                                    .then(res => res.json())
                                    .then((response) => {
                                        setServerError(false)
                                        setFilterType(response)
                                        fetch(`${apiPath}/v1/filter/filter/{desc}?desc=RICERCA_SPESE_GIUDIZIO`, {
                                            method: 'GET',
                                            headers: {
                                                'Content-Type': 'application/json',
                                            }
                                        })
                                            .then(res => res.json())
                                            .then((response) => {
                                                setServerError(false)
                                                setFilterSpeseGiudizio(response)
                                                fetch(`${apiPath}/v1/filter/filter/{desc}?desc=RICERCA_SENTENZA_IMPUGNATA`, {
                                                    method: 'GET',
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                    }
                                                })
                                                    .then(res => res.json())
                                                    .then((response) => {
                                                        setServerError(false)
                                                        setFilterSentence(response)
                                                        fetch(`${apiPath}/v1/filter/controversia/all`, {
                                                            method: 'GET',
                                                            headers: {
                                                                'Content-Type': 'application/json',
                                                            }
                                                        })
                                                            .then(res => res.json())
                                                            .then((response) => {
                                                                setServerError(false)
                                                                setFilterControversia(response)
                                                                fetch(`${apiPath}/v1/filter/esito/descMacro`, {
                                                                    method: 'GET',
                                                                    headers: {
                                                                        'Content-Type': 'application/json',
                                                                    }
                                                                })
                                                                    .then(res => res.json())
                                                                    .then((response) => {
                                                                        setServerError(false)
                                                                        setFilterEsito(response)
                                                                        fetch(`${apiPath}/v1/filter/materia/distinct`, {
                                                                            method: 'GET',
                                                                            headers: {
                                                                                'Content-Type': 'application/json',
                                                                            }
                                                                        })
                                                                            .then(res => res.json())
                                                                            .then((response) => {
                                                                                setServerError(false)
                                                                                setFilterMateria(response)


                                                                                fetch(`${apiPath}/v1/filter/autorita/all`, {
                                                                                    method: 'GET',
                                                                                    headers: {
                                                                                        'Content-Type': 'application/json',
                                                                                    }
                                                                                })
                                                                                    .then(res => res.json())
                                                                                    .then((response) => {
                                                                                        setServerError(false)
                                                                                        setFilterAutoritaAll(response)
                                                                                        fetch(`${apiPath}/v1/filter/materia/classificazione/all`, {
                                                                                            method: 'GET',
                                                                                            headers: {
                                                                                                'Content-Type': 'application/json',
                                                                                            }
                                                                                        })
                                                                                            .then(res => res.json())
                                                                                            .then((response) => {
                                                                                                setServerError(false)
                                                                                                setFilterMateriaClass(response)
                                                                                                fetch(`${apiPath}/v1/filter/esito/all`, {
                                                                                                    method: 'GET',
                                                                                                    headers: {
                                                                                                        'Content-Type': 'application/json',
                                                                                                    }
                                                                                                })
                                                                                                    .then(res => res.json())
                                                                                                    .then((response) => {
                                                                                                        setServerError(false)
                                                                                                        setFilterEsitoAll(response)
                                                                                                        if (!returnFromDetails) {
                                                                                                            setReady(true)
                                                                                                        }
                                                                                                    })
                                                                                                    .catch(() => { setServerError(true) })
                                                                                            })
                                                                                            .catch(() => { setServerError(true) })
                                                                                    })
                                                                                    .catch(() => { setServerError(true) })
                                                                            })
                                                                            .catch(() => { setServerError(true) })
                                                                    })
                                                            })
                                                            .catch(() => { setServerError(true) })
                                                    })
                                                    .catch(() => { setServerError(true) })
                                            })
                                            .catch(() => { setServerError(true) })
                                    })
                                    .catch(() => { setServerError(true) })
                            })
                            .catch(() => { setServerError(true) })
                    })
                    .catch(() => { setServerError(true) })
            })
            .catch(() => { setServerError(true) })
    }, []);

    const validateForm = (
        request: {
            annoSentenza: number | string
            autoritaEmittente: Array<string>
            campoRicerca: string
            classificazione: string
            emissioneFine: string
            emissioneInizio: string
            esitoGiudizio: string
            gradoAutorita: string
            materia: string
            modRicerca: string
            numeroSentenza: string
            tipoGiudizio: string
            valoreControversiaMin: string
            valoreControversiaMax: string
        }) => {
        if (request) {
            if (request?.campoRicerca.trim() !== "") {
                const regexDeclined = /[!{}´`£$@#^§ç%&\/=?*<>[\]]/
                if (new String(request?.campoRicerca).length < 3) {
                    setIsErrorText(true)
                    isErrorTextRef.current = true
                    setErrorMessageText("Inserire una parola di almeno 3 caratteri")
                    setTimeout(() => {
                        const error = document.getElementsByClassName('Search')[0]
                        error?.scrollIntoView()
                    }, 100);
                }
                else if (new String(request?.campoRicerca).length > 100) {
                    setIsErrorText(true)
                    isErrorTextRef.current = true
                    setErrorMessageText("E' possibile inserire massimo 100 caratteri")
                    setTimeout(() => {
                        const error = document.getElementsByClassName('Search')[0]
                        error?.scrollIntoView()
                    }, 100);
                }
                else if (request?.campoRicerca.match(regexDeclined)) {
                    setIsErrorText(true)
                    isErrorTextRef.current = true
                    setErrorMessageText("Caratteri non ammessi")
                    setTimeout(() => {
                        const firstError = document.querySelectorAll('[role="alert"]')[0]
                        firstError?.scrollIntoView()
                    }, 100);
                }
                else {
                    setIsErrorText(false)
                    isErrorTextRef.current = false
                    setErrorMessageText("")
                }
            }
            else {
                setIsErrorText(false)
                isErrorTextRef.current = false
                setErrorMessageText("")
            }
            if (request?.numeroSentenza.length > 10) {
                setIsErrorNumero(true)
                isErrorNumeroRef.current = true
                setErrorMessageNumero("E' possibile inserire massimo 10 valori")
                setTimeout(() => {
                    const error = document.getElementsByClassName('Search')[0]
                    error?.scrollIntoView()
                }, 100);
            }
            else if (request?.numeroSentenza !== '') {
                const numbers = /^\s*[0-9]+\s*$/;
                if (!request?.numeroSentenza.match(numbers)) {
                    setIsErrorNumero(true)
                    isErrorNumeroRef.current = true
                    setErrorMessageNumero("Sono ammessi solo valori numerici")
                    setTimeout(() => {
                        const error = document.getElementsByClassName('Search')[0]
                        error?.scrollIntoView()
                    }, 100);
                }
                else {
                    setIsErrorNumero(false)
                    isErrorNumeroRef.current = false
                    setErrorMessageNumero("")
                }
            }
            else {
                isErrorNumeroRef.current = false
                setIsErrorNumero(false)
                setErrorMessageNumero("")
            }
            if (request.emissioneInizio !== "" &&
                request.emissioneFine !== "" &&
                request.emissioneInizio?.toString() !== INVALID_DATE?.toString() &&
                request.emissioneFine?.toString() !== INVALID_DATE?.toString()) {
                if (request.emissioneFine <= request.emissioneInizio) {
                    setInvalidDateError(true);
                    setInvalidDateErrorMessage("La 'Data deposito da' non può essere successiva alla 'Data deposito a'")
                    isErrorDateRef.current = true;
                    setTimeout(() => {
                        const error = document.getElementById('alert-danger')
                        error?.scrollIntoView()
                    }, 100);
                } else {
                    setInvalidDateError(true);
                    setInvalidDateErrorMessage("");
                    isErrorDateRef.current = false;
                }
            }
            else {
                setInvalidDateError(true);
                setInvalidDateErrorMessage("");
                isErrorDateRef.current = false;
            }
        }
    }

    useEffect(() => {
        if (request?.filter.campoRicerca.trim() !== '' || request?.filter.annoSentenza !== "") {
            setShowAlert(false)
        }
    }, [request])

    const buildAutoritaEmittente = (gradoCommissione: string, codiceCommissione: string) => {
        let court = ""
        if (gradoCommissione) {
            court = `CGT ${gradoCommissione + "°"}`
            if (gradoCommissione == "1") {
                return `${court} ${filterAutoritaAll.filter(value => value.codice === codiceCommissione)[0]?.descrizione}`
            }
            else if (gradoCommissione == "2") {
                return `${court} ${filterAutoritaAll.filter(value => value.codice === codiceCommissione)[0]?.regione}`
            }
        }
        return `CGT ${filterAutoritaAll.filter(value => value.codice === codiceCommissione)[0]?.descrizione}`
    }

    const reloadPage = () => {
        setShowModal(false)
        window.location.href = location.pathname;
    }

    const searchRequest = (pageNumber: number, pageSize: number) => {
        const requestFromLocal = JSON.parse(localStorage.getItem("request") as string)
        setShowModal(false)
        fetch(`${apiPath}/v1/search/submit`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                filter: requestFromLocal?.filter,
                options: {
                    enableSummary: true,
                    pageSize: pageSize,
                    pageNumber: pageNumber
                }
            }),
            redirect: "manual"
        })
            .then(res => {
                // Controlla se lo status è 302
                if (res.type == 'opaqueredirect' || res.redirected || res.status === 302) {
                    // Visualizza modale sessione scadut
                    return Promise.reject({ type: 'redirect', res });
                }
                // Continua con il normale flusso di gestione della risposta
                return res.json();
            })
            .then((response) => {
                if (response.statusCode === 400) {
                    //controllo se l'errore è dovuto ad una data non valida
                    if (response.messageCause === 'DATE_ERR') {
                        setInvalidDateError(true);
                        setInvalidDateErrorMessage(response.message)
                        //controllo quale data è invalida e la resetto
                        if (request.filter.emissioneInizio.toString() === INVALID_DATE.toString()) {
                            dispatch(setEmissioneInizio(""))
                            setUnmountDataInizio(true)
                            setTimeout(() => {
                                setUnmountDataInizio(false)
                            }, 0);
                        }
                        if (request.filter.emissioneFine.toString() === INVALID_DATE.toString()) {
                            dispatch(setEmissioneFine(""))
                            setUnmountDataFine(true)
                            setTimeout(() => {
                                setUnmountDataFine(false)
                            }, 0);
                        }
                    }
                    else {
                        setShowAlertErrorBE(true)
                        setTimeout(() => {
                            const firstError = document.getElementById('alert-danger')
                            firstError?.scrollIntoView()
                        }, 300);
                        setMessage("Errore in fase di ricerca")
                        setShowErrorMaxResultLength(false);
                    }
                    setTimeout(() => {
                        const firstError = document.getElementById('alert-danger')
                        firstError?.scrollIntoView()
                    }, 300);
                    setShowResult(false)
                    setResultTableData([])
                    setLoadedData(false)
                } else {
                    if (response.statusCode === 500) {
                        setShowAlertErrorBE(true)
                        setTimeout(() => {
                            const firstError = document.getElementById('alert-danger')
                            firstError?.scrollIntoView()
                        }, 300);
                        setMessage("Errore in fase di ricerca")
                        setShowErrorMaxResultLength(false);
                        setTimeout(() => {
                            const firstError = document.getElementById('alert-danger')
                            firstError?.scrollIntoView()
                        }, 300);
                        setShowResult(false)
                        setResultTableData([])
                        setLoadedData(false)
                        localStorage.removeItem('response')
                        localStorage.removeItem('paginationDetails')
                        setUnmountDataInizio(false)
                        setUnmountDataFine(false)
                        setInvalidDateError(false)
                    }
                    else {
                        localStorage.removeItem('response')
                        localStorage.removeItem('paginationDetails')
                        setUnmountDataInizio(false)
                        setUnmountDataFine(false)
                        setInvalidDateError(false)
                        setResponseLength(response.searchTotalResult)
                        setResponseLimit(response.limitResult)
                        const searchLimit = response.limitResult
                        if (response.searchTotalResult <= searchLimit) {
                            setShowErrorMaxResultLength(false)
                            const result: Array<{
                                anno: "",
                                autoritaEmittente: string,
                                dataEmissione: "",
                                esito: "",
                                idFilenet: "",
                                idPGD: "",
                                numero: "",
                                tipoGiudizio: "",
                                valoreControversia: any,
                                flagSpeseGiudizio: any,
                                importoSpeseGiudizioParte: any,
                                importoSpeseGiudizioUfficio: any
                            }> = []
                            response?.result?.map((val: any) => {
                                const matClass = val.materiaClassificazione.split(";;")
                                const classificazioni: Array<{ mat: string, class: string }> = []
                                const materie: Array<string> = []
                                matClass.map((couple: string, index: number) => {
                                    if (matClass.length === 1) {
                                        const codiceMateria = couple.substring(couple.indexOf(";") + 1, couple.indexOf("-"))
                                        const codiceClassificazione = couple.substring(couple.indexOf("-") + 1, couple.lastIndexOf(";"))
                                        materie.push(codiceMateria)
                                        classificazioni.push({ mat: codiceMateria, class: codiceClassificazione })
                                    }
                                    else {
                                        if (index === 0) {
                                            const codiceMateria = couple.substring(couple.indexOf(";") + 1, couple.indexOf("-"))
                                            const codiceClassificazione = couple.substring(couple.indexOf("-") + 1)
                                            materie.push(codiceMateria)
                                            classificazioni.push({ mat: codiceMateria, class: codiceClassificazione })
                                        }
                                        else if (index === matClass.length - 1) {
                                            const codiceMateria = couple.substring(0, couple.indexOf("-"))
                                            const codiceClassificazione = couple.substring(couple.indexOf("-") + 1, couple.indexOf(";"))
                                            materie.push(codiceMateria)
                                            classificazioni.push({ mat: codiceMateria, class: codiceClassificazione })
                                        }
                                        else {
                                            const codiceMateria = couple.substring(0, couple.indexOf("-"))
                                            const codiceClassificazione = couple.substring(couple.indexOf("-") + 1)
                                            materie.push(codiceMateria)
                                            classificazioni.push({ mat: codiceMateria, class: codiceClassificazione })
                                        }

                                    }
                                })
                                const materiaClassificazioneDesc: Array<string> = []
                                const classificazioneDescription: Array<string> = []
                                classificazioni.map((val) => {
                                    const temp = filterMateriaClass.filter(value => val.mat === value.codiceMateria && val.class === value.codiceClassificazione)
                                    if (temp.length > 0) {
                                        classificazioneDescription.push(temp[0].descrizioneClassificazione)
                                        materiaClassificazioneDesc.push(`${temp[0].descrizioneMateria}: ${temp[0].descrizioneClassificazione}`)
                                    }
                                })
                                const materiaDescription: Array<string> = []
                                materie.map((val) => {
                                    const temp = filterMateriaClass.filter(value => val === value.codiceMateria)
                                    if (temp.length > 0) {
                                        materiaDescription.push(temp[0].descrizioneMateria)
                                    }
                                })

                                const valControversia = (parseFloat(val.valoreControversia).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
                                const giudizioParte = val?.importoSpeseGiudizioParte ? (parseFloat(val?.importoSpeseGiudizioParte)?.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })) : null
                                const giudizioUfficio = val?.importoSpeseGiudizioUfficio ? (parseFloat(val?.importoSpeseGiudizioUfficio)?.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })) : null
                                let tempElement = {
                                    anno: val.anno,
                                    autoritaEmittente: buildAutoritaEmittente(val.gradoCommissione, val.autoritaEmittente),
                                    dataEmissione: val.dataEmissione,
                                    esito: filterEsitoAll?.filter(value => value.codiceEsito === val.esito)[0]?.descrizioneEsito,
                                    macroEsito: filterEsitoAll?.filter(value => value.codiceMacroesito === val.esito)[0]?.descrizioneMacroesito,
                                    idFilenet: val.idFilenet,
                                    idPGD: val.idPGD,
                                    numero: val.numero,
                                    tipoGiudizio: filterType?.filter(value => val.tipoGiudizio === value.descrizione)[0]?.codice,
                                    valoreControversia: valControversia,
                                    materia: materiaDescription,
                                    materiaCode: materie,
                                    classificazione: classificazioneDescription,
                                    classificazioneCode: classificazioni,
                                    materiaClassificazione: materiaClassificazioneDesc,
                                    flagSpeseGiudizio: filterSpeseGiudizio?.filter(value => val?.flagSpeseGiudizio === value?.descrizione)[0]?.codice,
                                    speseGiudizioDesc: val.speseGiudizioDesc,
                                    importoSpeseGiudizioParte: giudizioParte,
                                    importoSpeseGiudizioUfficio: giudizioUfficio,
                                    statoGiudizio: val.statoGiudizio
                                }
                                result.push(tempElement)
                            })
                            if (result.length > 0) {
                                dispatch(setResults(result))
                                localStorage.setItem("response", JSON.stringify(result));
                                localStorage.setItem("paginationDetails", JSON.stringify({
                                    totalElements: response.searchTotalResult,
                                    pageNumber: response.pageNumber,
                                    totalPages: response.pageTotal
                                }));
                                dispatch(setPaginationDetails({
                                    totalElements: response.searchTotalResult,
                                    pageNumber: response.pageNumber,
                                    totalPages: response.pageTotal
                                }))
                                setResultTableData(result)
                                setCurrentPage(response.pageNumber);
                                setLoadedData(true)
                                setTimeout(() => {
                                    const table = document.getElementById('table-title')
                                    table?.scrollIntoView()
                                }, 100);
                                if (returnFromDetails) {
                                    setReady(true)
                                    setShowResult(true)
                                    dispatch(setReturnFromDetails(false))
                                }
                            }
                            else {
                                setMessage(CONSTANTS.SEARCH_PAGE.emptySearchMessage)
                                setShowResult(false)
                                setResultTableData([])
                                setLoadedData(false)
                            }
                        }
                        else {
                            setShowErrorMaxResultLength(true)
                            setShowResult(false)
                            setResultTableData([])
                            setLoadedData(false)
                            setInvalidDateError(false)
                        }
                    }
                }

            })
            .catch((val) => {

                if (val.type === 'redirect') {
                    // Gestisci il caso di redirect
                    setShowModal(true); // Attiva la modale quando ricevi un 302
                } else {

                    if (val.messageCause === 'SEARCH_WATSON') {
                        // console.log(val.message);
                    }
                    setShowAlertErrorBE(true)
                    setTimeout(() => {
                        const firstError = document.getElementById('alert-danger')
                        firstError?.scrollIntoView()
                    }, 300);
                    setMessage(CONSTANTS.SEARCH_PAGE.emptySearchMessageFirstTime)
                    setShowResult(false)
                    setResultTableData([])
                    setLoadedData(false)
                }
            })
    }

    const submit = () => {
        searchRequest(1, 10);
    }

    const showResultHandler = (val: boolean) => {
        setShowAlert(false)
        setShowAlertErrorBE(false)
        if (!val) {
            setShowResult(val)
            setLoadedData(false)
            setResultTableData([])
            setMessage(CONSTANTS.SEARCH_PAGE.emptySearchMessageFirstTime)
            clearAll()
        }
        else {
            localStorage.setItem("request", JSON.stringify(request));
            validateForm(request.filter)
            if ((request?.filter.annoSentenza !== 0 && request?.filter.annoSentenza !== "") || request?.filter.campoRicerca.trim() !== "") {
                if (!isErrorNumeroRef.current && !isErrorTextRef.current && !isErrorDateRef.current) {
                    setResultTableData([])
                    setLoadedData(false)
                    //send POST for search results
                    setShowResult(val);
                    setTimeout(() => {
                        const spinner = document.getElementById('spinner-search')
                        spinner?.scrollIntoView()
                    }, 0);
                    dispatch(setReturnFromDetails(false))
                    submit()
                }
                else {
                    setShowResult(false)
                }
            }
            else {
                setMessage(CONSTANTS.SEARCH_PAGE.emptySearchMessageFirstTime)
                setShowAlert(true)
                setTimeout(() => {
                    const firstError = document.getElementById('alert-danger')
                    firstError?.scrollIntoView()
                }, 300);
            }
        }

    }

    const clearAll = () => {
        setReady(false)
        dispatch(clearRequest(""))
        setIsErrorText(false)
        isErrorTextRef.current = false
        setErrorMessageText("")
        setIsErrorNumero(false)
        isErrorNumeroRef.current = false
        setErrorMessageNumero("")
        clearRequestParams()
        setTimeout(() => {
            setReady(true)
        }, 0);
    }

    //popolo autorità by id nella ricerca
    useEffect(() => {
        if (request?.filter?.gradoAutorita !== '' /*&& !returnFromDetails*/) {
            let gradoId = filterGrado.filter((val) => val.descrizione === request?.filter?.gradoAutorita)[0]?.descrizione
            fetch(`${apiPath}/v1/filter/autorita/${gradoId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
                .then(res => res.json())
                .then((response) => {
                    let temp: Array<{}> = []
                    if (gradoId === '0') {
                        response.map((val: string) => {
                            temp.push({
                                codice: val,
                                descrizione: val
                            })
                        })
                    }
                    else {
                        temp = response
                    }
                    setFilterAutoritaById(temp)
                })
        }
    }, [request?.filter?.gradoAutorita])

    //popolo autorità by id nella ricerca quando ritorno dal dettaglio
    useEffect(() => {
        if (request?.filter?.gradoAutorita !== '' && filterGrado.length > 0 /*&& returnFromDetails*/) {
            dispatch(setReturnFromDetails(false));
            let gradoId = filterGrado.filter((val) => val.descrizione === request?.filter?.gradoAutorita)[0]?.descrizione
            fetch(`${apiPath}/v1/filter/autorita/${gradoId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
                .then(res => res.json())
                .then((response) => {
                    let temp: Array<{}> = []
                    if (gradoId === '0') {
                        response.map((val: string) => {
                            temp.push({
                                codice: val,
                                descrizione: val
                            })
                        })
                    }
                    else {
                        temp = response
                    }
                    setFilterAutoritaById(temp)
                })
        }
    }, [request?.filter?.gradoAutorita, filterGrado])

    useEffect(() => {
        if (request?.filter?.materia !== '') {
            fetch(`${apiPath}/v1/filter/classificazione/${request?.filter.materia}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
                .then(res => res.json())
                .then((response) => {
                    setFilterClassificazione(response)
                })
        }
    }, [request?.filter?.materia])

    const clearRequestParams = () => {
        dispatch(clearRequest(""))
        localStorage.removeItem('request')
        localStorage.removeItem('response')
        localStorage.removeItem('paginationDetails')
    }

    return (
        <>
            <div className="container py-3">
                <Row className="my-3">
                    <Col className="col-md-0">
                        <nav aria-label="Ti trovi in">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to='/' onClick={clearRequestParams}>{CONSTANTS.SEARCH_DETAILS_PAGE.searchDetailsPathHomeLinkLabel}</Link>
                                </li>
                                <li className="color-black breadcrumb-item active" aria-current="page">{CONSTANTS.SEARCH_PAGE.searchFormTitle}</li>
                            </ol>
                        </nav>
                    </Col>
                </Row>
            </div>
            {!ready ?
                serverError ?
                    <Error message="si è verificato un errore!" />
                    :
                    <Spinner />
                :
                ready ?
                    <div className="Search">
                        <div role='region' aria-label="Form di Ricerca Sentenze mascherate">
                            <SearchForm
                                filterList={filterAll}
                                yearsList={filterYears}
                                autorityGradeList={filterGrado}
                                autorityList={filterAutoritaById}
                                sentenzaImpugnataList={filterSentence}
                                disputeList={filterControversia}
                                judgementTypeList={filterType}
                                judgementOutcomeList={filterEsito}
                                matterList={filterMateria}
                                classificationList={filterClassificazione}
                                speseGiudizioList={filterSpeseGiudizio}
                                showResultHandler={showResultHandler}
                                isErrorText={isErrorText}
                                isErrorNumero={isErrorNumero}
                                errorMessageText={errorMessageText}
                                errorMessageNumero={errorMessageNumero}
                                showAlert={showAlert}
                                showAlertErrorBE={showAlertErrorBE}
                                showLoader={showResult && !loadedData}
                                invalidDateError={invalidDateError}
                                invalidDateErrorMessage={invalidDateErrorMessage}
                                unmountDataInizio={unmountDataInizio}
                                unmountDataFine={unmountDataFine}

                            />
                        </div>
                        <div role='region' aria-label="Risultati di ricerca">
                            {showResult ?
                                loadedData ?
                                    <>
                                        <CustomTable
                                            tableData={resultTableData}
                                            filterList={filterAll}
                                            yearsList={filterYears}
                                            autorityGradeList={filterGrado}
                                            autorityList={filterAutoritaById}
                                            disputeList={filterControversia}
                                            judgementTypeList={filterType}
                                            judgementOutcomeList={filterEsito}
                                            matterList={filterMateria}
                                            classificationList={filterMateriaClass}
                                            speseGiudizioList={filterSpeseGiudizio}
                                            responseLength={paginationDetails.totalElements}
                                        />
                                        <nav className="pagination justify-content-center" aria-label="Paginazione ...">
                                            <Pagination
                                                currentPage={paginationDetails.pageNumber}
                                                lastPage={paginationDetails.totalPages}
                                                maxLength={7}
                                                setCurrentPage={setCurrentPage}
                                                setClickedPageButton={setClickedPageButton}
                                            />
                                        </nav>
                                        <br></br>
                                    </>
                                    :
                                    <Spinner id='spinner-search' />
                                : showErrorMaxResultLength ?
                                    <Container className="py-3 mb-5">
                                        <Row className='text-align-center'>
                                            <label className="color-black">
                                                <p><strong>Sentenze trovate: {numberWithCommas(responseLength)} </strong> </p>
                                                Il numero delle sentenze trovate supera il valore massimo consentito ({numberWithCommas(responseLimit)}). Utilizzare i parametri di ricerca per restringere il numero di risultati.
                                            </label>
                                        </Row>
                                    </Container>
                                    :
                                    <Container className="py-3 mb-5">
                                        <Row className='text-align-center'>
                                            <span className="color-black">
                                                {message}
                                            </span>
                                        </Row>
                                    </Container>
                            }
                        </div>

                    </div>
                    : null
            }
            <ModalSessionExpired title="Attenzione" content="La sessione è scaduta, clicca su 'Ricarica' per riaccedere al servizio." show={showModal} onReload={() => reloadPage()}></ModalSessionExpired>

        </>

    );
}

export default Search;

import React, { useEffect, useRef, useState } from "react";
import { Link } from 'react-router-dom'
import { Col, Row, Button, Table, Container } from "react-bootstrap";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { CONSTANTS } from "../utils/constants";
import { useAppSelector } from "../store/store";
import './styles.css';
import Modal from "./Modal";

type CustomTableProps = {
  tableData: Array<any>
  filterList: Array<any>,
  yearsList: Array<any>,
  autorityGradeList: Array<any>,
  autorityList: Array<any>,
  disputeList: Array<any>,
  judgementTypeList: Array<any>,
  judgementOutcomeList: Array<any>,
  matterList: Array<any>,
  classificationList: Array<any>,
  speseGiudizioList: Array<any>,
  responseLength:any
}

const CustomTable = (props: CustomTableProps) => {
  const [tableRowsNoAction, setTableRowsNoAction] = useState<Array<any>>([])
  const tableRowsRef = useRef<Array<any>>([])
  const [currentTableRows, setCurrentTableRows] = useState<Array<any>>([])
  const tableRef = useRef(null);
  const { request } = useAppSelector((state: any) => state.searchResults);

  const base64ToArrayBuffer = (base64: string) => {
    const binaryString = window.atob(base64);
    const bytes = new Uint8Array(binaryString.length);
    return bytes.map((byte, i) => binaryString.charCodeAt(i));
  }

  function numberWithCommas(number: number) {
    let parseNumber = number?.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(parseNumber))
        parseNumber = parseNumber?.replace(pattern, "$1.$2");
    return parseNumber;
}

  const modalDownloadSentenzeTitle = `Elenco risultati della ricerca`;
  const modalDownloadSentenzeContent = `
  <p>L’elenco, visualizzato per pagine di 10 sentenze ciascuna, contiene le seguenti informazioni:</p>
  <ul>
    <li><b>Numero sentenza:</b> Numero della sentenza</li>
    <li><b>Anno sentenza:</b> Anno in cui è stata depositata la sentenza</li>
    <li><b>Autorità emittente:</b> Corte di Giustizia Tributaria presso la quale è stata depositata la sentenza</li>
    <li><b>Data deposito:</b> Data in cui è stata depositata la sentenza</li>
    <li><b>Valore controversia:</b> Il valore della controversia di una sentenza è calcolato come la somma di tutti gli importi dei singoli ricorsi al netto di interessi e sanzioni</li>
    <li><b>Tipo giudizio:</b> tipologia di giudizio, collegiale o monocratico</li>
    <li><b>Esito giudizio:</b> esito della sentenza</li>
    <li><b>Stato giudizio:</b> informazione disponibile solo per le sentenze di primo grado, può assumere i seguenti valori:</li>
    <ul>
      <li>appello non presente</li>
      <li>appello presente</li>
      <li>sentenza di secondo grado (nel caso in cui l’appello sia stato definito e la relativa sentenza di secondo grado sia stata depositata, anonimizzata e consultabile in Banca Dati)</li>
    </ul>
    </ul>

    <br>
    <p>Tramite le due icone presenti nella colonna <b>Azioni</b> è possibile:</p>
    <ol>
      <li>scaricare (tramite l'icona <i class="bi bi-download mx-2"></i>) la sentenza anonimizzata in formato pdf direttamente dall’elenco</li>
      <li>visualizzare (tramite l'icona <i class="bi bi-search"></i>) la sentenza anonimizzata e le informazioni di dettaglio.</li>
    </ol>
  `;

  const createAndDownloadBlobFile = (body: any, filename: string, extension = 'pdf') => {
    const blob = new Blob([body]);
    const fileName = `${filename}`;
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  const downloadPdf = (idFilenet: any) => {
    if (window._paq) {
      window._paq.push(['trackLink', String(window.location)+'/download', 'download']);
    }
    const apiPath = process.env.REACT_APP_SERVER_HOST_PUBLIC
    fetch(`${apiPath}/v1/search/content/${idFilenet}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(res => res.json())
      .then((response) => {
        const arrayBuffer = base64ToArrayBuffer(response.content);
        createAndDownloadBlobFile(arrayBuffer, response.nomeFile !== "" ? response.nomeFile : "noNameFile");
      })
  }

  const removeDuplicated = (arrayDuplicated: Array<any>) => {
    return arrayDuplicated.filter((item, pos) =>  arrayDuplicated.indexOf(item) === pos)
  }


  const _getTableRows = () => {
    var rowList = [];
    if (props.tableData.length > 0) {
      for (let i = 0; i < props.tableData.length; i++) {
        var string = (
          <tr key={props.tableData[i].idFilenet}>
            <span className="visually-hidden">{i}</span>
            <td className="text-center">{props.tableData[i].numero}</td>
            <td className="text-center">{props.tableData[i].anno}</td>
            <td className="text-center">{props.tableData[i].autoritaEmittente}</td>
            <td className="text-center">{props.tableData[i].dataEmissione}</td>
            <td className="text-end">{props.tableData[i].valoreControversia} €</td>
            <td className="text-center">{props.tableData[i].tipoGiudizio}</td>
            <td className="text-center">{props.tableData[i].macroEsito}</td>
            { request?.filter?.gradoAutorita !== "2" && <td className="text-center hover-target" title={`${props.tableData[i].statoGiudizio.includes('-') ? "Informazione non disponibile" : ""}`}>
              {props.tableData[i].statoGiudizio}
              </td>}
            {/* <td className="text-end">
              <Button variant="link" href="#" title="Segnala">
                {CONSTANTS.SEARCH_PAGE.reportButtonLabel}
              </Button>
            </td> */}
            <td className="text-center" >
              <Button variant="link" href="#" id={props.tableData[i].idFilenet} title={`Scarica il pdf della sentenza n. ${props.tableData[i].numero}/${props.tableData[i].anno} ${props.tableData[i].autoritaEmittente}`} >
                {/* {CONSTANTS.SEARCH_PAGE.downloadButtonLabel} */}
                <span className="visually-hidden">{i}</span>
                <i className="bi bi-download mx-2" onClick={() => downloadPdf(props.tableData[i].idFilenet)}></i>
              </Button>
            </td>
            <td className="text-start">
              <span className="visually-hidden">{i}</span>
              <Link to={`dettaglio`} state={props.tableData[i]} title={`Visualizza sentenza n. ${props.tableData[i].numero}/${props.tableData[i].anno} ${props.tableData[i].autoritaEmittente}`}>
                {/* {CONSTANTS.SEARCH_PAGE.showButtonLabel} */}
                <i className="bi bi-search"></i>
              </Link>
            </td>
          </tr>
        );
        rowList.push(string);
      }
      tableRowsRef.current = rowList

      var rowListNoAction = [];
      for (var row of props.tableData) {
        let mat = ""
        let matCode = ""
        let arrayUniq = []
        if (row.materia?.length === 0) {
          //matCode = row?.classificazioneCode?.length > 0 ? row?.classificazioneCode[0]?.mat !== ';' ? `(${row?.classificazioneCode[0]?.mat})` : "" : ""
          mat = '-'//`Descrizione non trovata ${matCode}`
        }
        else if (row.materia.length === 1) {
          mat = row.materia
        }
        else{
          arrayUniq = removeDuplicated(row.materia)
        }
        
        var string = (
          <tr>
            <td className="text-center">{row.numero}</td>
            <td className="text-center">{row.anno}</td>
            <td className="text-center">{row.autoritaEmittente}</td>
            <td className="text-center">{row.dataEmissione}</td>
            <td className="text-end">{row.valoreControversia}</td>
            <td className="text-center">{row.tipoGiudizio}</td>
            <td className="text-center">{row.macroEsito}</td>
            <td className="text-center">
              {arrayUniq?.length > 1 ?
                arrayUniq.map((val: string) => {
                  return (
                    <div>
                      {val}
                    </div>
                  )
                })
                : mat}
            </td>
            <td className="text-end">{row.speseGiudizioDesc}</td>
            <td className="text-end">{row.importoSpeseGiudizioParte}</td>
            <td className="text-end">{row.importoSpeseGiudizioUfficio}</td>
            {request?.filter?.gradoAutorita !== "2" && <td className="text-center">{row.statoGiudizio}</td>}
          </tr>
        );
        rowListNoAction.push(string);
      }
      setTableRowsNoAction(rowListNoAction)
    }
  }

  useEffect(() => {
    if(props.tableData?.length > 0){
      _getTableRows()
      setCurrentTableRows(tableRowsRef.current)
    }
  }, [props.tableData])

  const reformatDate = (date: any) => {
    let finalDate = ""
    if (date) {
      const year = date.toString().substring(0, 4)
      const month = date.toString().substring(4, 6)
      const day = date.toString().substring(6)
      finalDate = `${year}-${month}-${day}`
    }
    return finalDate;
  }

  const tableForDownloadToExcell = () => {
    return (
      <div ref={tableRef}>
        <Table responsive className='display-none'>
          <caption className="visually-hidden"><td scope="col" colSpan={request?.filter?.emissioneFine !== "" || request?.filter?.emissioneInizio !== "" ? 4 : 2}><strong>Criteri di ricerca selezionati:</strong></td></caption>
          <thead>
            {request?.filter?.modRicercaDescrizione !== "" && request?.filter?.campoRicerca !== "" ?
              <tr>
                <td scope="col"><strong>Modalità ricerca:</strong></td>
                <td scope="col">{request?.filter?.modRicercaDescrizione}</td>
              </tr>
              : null}
            {request?.filter?.campoRicerca !== "" ? <tr>
              <td scope="col"><strong>Campo ricerca:</strong></td>
              <td scope="col">{request?.filter?.campoRicerca}</td>
            </tr>
              : null}
            {request?.filter?.numeroSentenza !== "" ?
              <tr>
                <td scope="col"><strong>Numero sentenza:</strong></td>
                <td scope="col">{request?.filter?.numeroSentenza}</td>
              </tr>
              : null}
            {request?.filter?.annoSentenza !== "" ?
              <tr>
                <td scope="col"><strong>Anno sentenza:</strong></td>
                <td scope="col">{request?.filter?.annoSentenza}</td>
              </tr>
              : null}
            {(request?.filter?.emissioneInizio && request?.filter?.emissioneInizio !== "") || (request?.filter?.emissioneFine && request?.filter?.emissioneFine !== "") ?
              <tr>
                <td scope="col"><strong>Data deposito da:</strong></td>
                <td scope="col">{request?.filter?.emissioneInizio && request?.filter?.emissioneInizio !== "" ? reformatDate(request?.filter?.emissioneInizio) : '-'}</td>
                <td scope="col"><strong>a:</strong></td>
                <td scope="col">{request?.filter?.emissioneFine && request?.filter?.emissioneFine !== "" ? reformatDate(request?.filter?.emissioneFine) : '-'}</td>
              </tr>
              : null}
            {request?.filter?.gradoAutorita !== "" ?
              <tr>
                <td scope="col"><strong>Grado autorità:</strong></td>
                <td scope="col">{props.autorityGradeList.filter((val) => val.descrizione === request?.filter?.gradoAutorita)[0]?.codice}</td>
              </tr>
              : null}
            {request?.filter?.autoritaEmittente.length > 0 ?
              <tr>
                <td scope="col"><strong>Autorità emittente:</strong></td>
                <td>
                  {
                    request?.filter?.autoritaEmittente.length > 1 ?
                      request?.filter?.autoritaEmittente.map((el: string, i: number) => {
                        const another = i < request?.filter?.autoritaEmittente.length - 1 ? ", " : ""
                        return (
                          props.autorityList.filter((val) => val.codice === el)[0]?.descrizione + another
                        )
                      })
                      : props.autorityList.filter((val) => val.codice === request?.filter?.autoritaEmittente[0])[0]?.descrizione
                  }
                </td>
              </tr>
              : null}
            {request?.filter?.valoreControversiaMin !== "" ?
              <tr>
                <td scope="col"><strong>Valore controversia:</strong></td>
                <td scope="col">{props.disputeList.filter((val) => val.minValue === request?.filter?.valoreControversiaMin && val.maxValue === request?.filter?.valoreControversiaMax)[0]?.descrizione}</td>
              </tr>
              : null}
            {request?.filter?.tipoGiudizio !== "" ?
              <tr>
                <td scope="col"><strong>Tipo giudizio:</strong></td>
                <td scope="col">{props.judgementTypeList.filter((val) => val.descrizione === request?.filter?.tipoGiudizio)[0]?.codice}</td>
              </tr>
              : null}
            {request?.filter?.esitoGiudizio !== "" ?
              <tr>
                <td scope="col"><strong>Esito giudizio:</strong></td>
                <td scope="col">{props.judgementOutcomeList.filter((val) => val.codiceMacroesito === request?.filter?.esitoGiudizio)[0]?.descrizioneMacroesito}</td>
              </tr>
              : null}
            {request?.filter?.materia !== "" ?
              <tr>
                <td scope="col"><strong>Materia:</strong></td>
                <td scope="col">{props.matterList.filter((val) => val.codiceMateria === request?.filter?.materia)[0]?.descrizioneMateria}</td>
              </tr>
              : null}
            {request?.filter?.classificazione !== "" ?
              <tr>
                <td scope="col"><strong>Classificazione:</strong></td>
                <td scope="col">{props.classificationList.filter((val) => val.codiceClassificazione === request?.filter?.classificazione && val.codiceMateria === request?.filter?.materia)[0]?.descrizioneClassificazione}</td>
              </tr>
              : null}
            {request?.filter?.flagSpeseGiudizio !== "" ?
              <tr>
                <td scope="col"><strong>Spese giudizio:</strong></td>
                <td scope="col">{props.speseGiudizioList.filter((val) => val.descrizione === request?.filter?.flagSpeseGiudizio)[0]?.codice}</td>
              </tr>
              : null}
          </thead>
          <tr><td colSpan={8}></td></tr>
        </Table>

        <Table responsive className='display-none'>
          <caption className="visually-hidden"><strong>Elenco sentenze</strong></caption>
          <thead>
            <tr>
              <th scope="col" className="text-center">Numero sentenza</th>
              <th scope="col" className="text-center">Anno</th>
              <th scope="col" className="text-center">Autorità emittente</th>
              <th scope="col" className="text-center">Data deposito</th>
              <th scope="col" className="text-center">Valore controversia</th>
              <th scope="col" className="text-center">Tipo giudizio</th>
              <th scope="col" className="text-center">Esito giudizio</th>
              <th scope="col" className="text-center">Materia</th>
              <th scope="col" className="text-center">Spese giudizio</th>
              <th scope="col" className="text-center">Importo a carico del contribuente</th>
              <th scope="col" className="text-center">Importo a carico dell'ufficio</th>
              <th scope="col" className="text-center">Stato del giudizio</th>
            </tr>
          </thead>
          <tbody className="table-group-divider">{tableRowsNoAction}</tbody>
        </Table>
      </div>
    )
  }

  return (
    <Container className="py-3">
      <Row className="align-items-center">
        <Col>
          <h3 id='table-title'>
            <small>{CONSTANTS.SEARCH_PAGE.customTableTitle} ({numberWithCommas(props.responseLength)})</small>
          </h3>
        </Col>
        <Col className="col-3">
          <div className="d-flex justify-content-end align-items-center">
            <Button variant="link" href="#" title="Scarica elenco sentenze della pagina in formato excel" className='display-end'>
              {/* <i className="bi bi-download mx-2"></i> */}
              <DownloadTableExcel
                filename="Elenco sentenze"
                sheet="sentenze"
                currentTableRef={tableRef.current}
              >
                <span >Scarica elenco sentenze della pagina</span>
              </DownloadTableExcel>
            </Button>
          </div>
        </Col>
        <Row className="mt-3 px-0">
          <div className="d-flex justify-content-end">
            <i className="bi bi-info-circle info-cursor fs-3" data-bs-toggle="modal" data-bs-target="#search-modal-download" title="Informazioni sull'elenco dei risultati"></i>
          </div>
        </Row>
      </Row>
      {tableForDownloadToExcell()}
      {/* <div className="overflow-table"> */}
      <Table responsive>
        <caption className="visually-hidden">Risultati di ricerca</caption>
        <thead>
          <tr>
            <span className="visually-hidden">Indice</span>
            <th scope="col" className="text-center py-3 px-3">
              <span>Numero <br />sentenza</span>
            </th>
            <th scope="col" className="text-center py-3 px-3">
              <span>Anno</span>
            </th>
            <th scope="col" className="text-center py-3 px-3">
              <span>Autorità <br />emittente</span>
            </th>
            <th scope="col" className="text-center py-3 px-3">
              <span>Data <br />deposito</span>
            </th>
            <th scope="col" className="text-center py-3 px-3">
              <span>Valore <br />controversia</span>
            </th>
            <th scope="col" className="text-center py-3 px-3">
              <span>Tipo Giudizio</span>
            </th>
            <th scope="col" className="text-center py-3 px-3">
              <span>Esito giudizio</span>
            </th>
            {request?.filter?.gradoAutorita !== "2" && <th scope="col" className="text-center py-3 px-3">
              <span>Stato del <br />giudizio</span>
            </th>}
            <th scope="col" colSpan={2} className="text-center py-3 px-3">
              <span>Azioni</span>
            </th>
          </tr>
        </thead>
        <tbody className="table-group-divider">{currentTableRows}</tbody>
      </Table>
      {/* </div> */}
      <Modal title={modalDownloadSentenzeTitle} content={modalDownloadSentenzeContent} id="search-modal-download"></Modal>
    </Container>
  );
}

export default CustomTable;

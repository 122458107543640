import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import './styles.css';
import { useAppDispatch } from "../store/store";
import { clearRequest, } from "../store/search/reducer";

type errorProps = {
  message: string,
  styleContainer?: boolean,
  noBackToHome?: boolean
}

const Error = (props: errorProps) => {

  const searchDetailsDispatch = useAppDispatch();

  return (
    <div className={props.styleContainer ? "container py-3" : "container pb-3"}>
      <div id="alert-danger" className="alert alert-danger" role="alert">
        <p><strong>Siamo spiacenti...</strong></p>
        <p>{props.message}</p>
      </div>
      {!props.noBackToHome ?
        <Col>
          <Link to='/' onClick={() => searchDetailsDispatch(clearRequest(""))}>Torna alla Home</Link>
        </Col>
        : null}
    </div>
  );
}

export default Error;

import React from "react";
import { Form } from "react-bootstrap";
import './styles.css';
import { INVALID_DATE } from "../utils/constants";

type SearchItemProps = {
    id: string,
    title: string,
    placeholder?: string,
    type: string,
    list?: Array<any>,
    callback?: any,
    keyboardCallback?: any,
    field?: string,
    disabled?: boolean,
    value: string | number,
    isError?: boolean,
    errorMessage?: string,
    maxlength?: number,
    initialValue?: string | number;
    required?: boolean,
    note?: boolean,
    isValid?: boolean,
    disableAutocomplete?: boolean,
    subtitle?: string
}



const SearchItem = (props: SearchItemProps) => {

    let option: Array<any> = [];

    const checkEvent = (event: any) => {
        if (event.code.includes("Key") || event.code.includes("Backspace") || event.code.includes("Delete")) {
            props.keyboardCallback("")
        } else {
            if (event.target.value === "") {
                props.keyboardCallback(INVALID_DATE)
            }
            else {
                props.keyboardCallback(formatDate(event.target.value))
            }
        }
    }

    const checkOnChangeDate = (event: any) => {
        if (event.target.value === "") {
            props.callback("")
        }
        else {
            props.callback(formatDate(event.target.value))
        }
    }

    const renderOption = () => {
        // if(props.initialValue === ""){
        option.push(<option value="" selected={props.initialValue === ""}>Seleziona</option>)
        // }
        props?.list?.map(
            (val, id) => {
                option.push(
                    <option
                        selected={props.value && props.value !== "" && props.initialValue === val[props.value] ? true : false}
                        value={props.value && props.value !== "" ? val[props.value] : val.codice}
                    >
                        {props.field ? val[props.field] : val.codice}
                    </option>
                )
            }
        )
        return option;
    }

    const changeFunc = (e: any) => {
        const selectedOptions = e.currentTarget.selectedOptions;
        const selectedValues = [];
        for (let i = 0; i < selectedOptions.length; i++) {
            selectedValues.push(selectedOptions[i].value);
        }
        props.callback(selectedValues)
    }

    const formatDate = (date: string) => {
        const convertDate = new Date(date)
        const year = convertDate.getFullYear()
        const month = convertDate.getMonth() + 1
        const parseMonth = month < 10 ? 0 + month.toString() : month.toString()
        const day = convertDate.getDate()
        const parseDay = day < 10 ? 0 + day.toString() : day.toString()
        const finalDate = parseInt(year.toString() + parseMonth + parseDay)
        return finalDate
    }

    return (
        <div className="mb-3">
            {/* <Form>
            <Form.Group className="mb-3" controlId={props.id}> */}
            <Form.Label htmlFor={props.id} className="form-label">
                {props.title}:{props.required ? '*' : props.note ? '**' : null}
            </Form.Label>
            {
                props.subtitle ?
                    <p className="form-label"><i >{props.subtitle}</i></p>
                    : null
            }
            {props.type === 'control' ?
                <>
                    <Form.Control
                        id={props.id}
                        value={props.initialValue}
                        type="text"
                        autoComplete={props.disableAutocomplete ? 'off' : 'on'}
                        placeholder={props.placeholder}
                        onChange={(e) => props.callback(e.target.value)}
                        maxLength={props.maxlength}
                        className={props.isError ? "form-control is-invalid" : props.isValid ? "form-control is-valid" : ""}
                        disabled={props.disabled}
                    />
                    <div id={`${props.id}-input-invalid`} className={`invalid-feedback ${props.isError ? 'display-class' : 'display-class-unset'}`} role="alert">{props.errorMessage}</div>
                </>
                :
                props.type === 'textArea' ?
                    <>
                        <Form.Control
                            id={props.id}
                            value={props.value}
                            as="textarea"
                            rows={5}
                            placeholder={props.placeholder}
                            onChange={(e) => props.callback(e.target.value)}
                            maxLength={props.maxlength}
                            className={props.isError ? "form-control is-invalid" : ""}
                            disabled={props.disabled}
                        />
                        <div id={`${props.id}-input-invalid`} className={`invalid-feedback ${props.isError ? 'display-class' : 'display-class-unset'}`} role="alert">{props.errorMessage}</div>
                    </>
                    : null
            }
            {props.type === 'select' ?
                <>
                    <Form.Select
                        id={props.id}
                        onChange={(e) => props.callback(e.target.value)}
                        disabled={props.disabled}
                        className={props.isError ? "form-control is-invalid" : ""}
                    >
                        {renderOption()}
                    </Form.Select>
                    <div id={`${props.id}-input-invalid`} className={`invalid-feedback ${props.isError ? 'display-class' : 'display-class-unset'}`} role="alert">{props.errorMessage}</div>
                </>
                : null
            }
            {props.type === 'multiple-select' ?
                <Form.Select
                    id={props.id}
                    name="multiple-value"
                    onChange={(e) => { changeFunc(e) }}
                    disabled={props.disabled}
                    multiple
                >
                    {renderOption()}
                </Form.Select>
                : null
            }
            {props.type === 'date' ?
                <>
                    <Form.Control
                        id={props.id}
                        disabled={props.disabled}
                        defaultValue={props.initialValue}
                        type="date"
                        placeholder={props.placeholder}
                        onChange={(e) => checkOnChangeDate(e)}
                        max={new Date().toISOString().split('T')[0]}
                        onKeyUp={(e) => checkEvent(e)}
                        className={props.isError ? "form-control is-invalid" : props.isValid ? "form-control is-valid" : ""}
                    />
                    <div id={`${props.id}-input-invalid`} className={`invalid-feedback ${props.isError ? 'display-class' : 'display-class-unset'}`} role="alert">{props.errorMessage}</div>

                </>
                : null
            }
            {/* </Form.Group>
        </Form> */}
        </div>

    );
}

export default SearchItem;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import '../components/styles.css';
import InfoCard from "../components/InfoCard";
import Spinner from "../components/Spinner";
import Error from "../components/Error";
import { CONSTANTS } from "../utils/constants";

interface CardInterface {
  titoloHtml: string,
  testoHtml: string,
  dataPubblicazione: number
}

type HomeProps = {
  setCardsLength: Function
}

const HomePage = (props: HomeProps) => {

  useEffect(() => {
    window.document.title = "Home - " + CONSTANTS.ROOT_PAGE.headerTitle;
    try {
      if (window._paq) {
        window._paq.push(['setCustomUrl', String(window.location)]);
        window._paq.push(['setDocumentTitle', window.document.title]);
        window._paq.push(['setGenerationTimeMs', 0]);
        window._paq.push(['trackPageView']);
      }
    } catch (e) {
      console.error('trackPageView error', e);
    }
  }, []);


  const [ready, setReady] = useState(false)
  const [cards, setCards] = useState<Array<CardInterface>>([])
  const [isError, setIsError] = useState(false)

  const apiPath = process.env.REACT_APP_SERVER_HOST_PUBLIC;

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
      });
    }, 100);
    fetch(`${apiPath}/home/info`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((res) => {
        setIsError(false)
        if(res?.length > 0){
          setCards(res)
          props.setCardsLength(res.length)
        }
        setReady(true)
      })
      .catch(() => {
        setIsError(true)
      })
  }, [])

  return (
    isError ? <Error message="si è verificato un errore!" noBackToHome={true} /> :
      ready ?
        <div role='region' aria-label="home page" className="home-background" >
          <div className="grid-container container">
            <div className="info-container my-5">
              <Row>
                <span>
                  <h1 className="megamenu-title home-page-title">La banca dati delle sentenze tributarie</h1>
                </span>
              </Row>
              <Row>
                <Col className="col-lg-7 col-md-10 col-sm-12">
                  <span className="lead">
                    E' attiva, in via sperimentale, la Banca Dati della Giurisprudenza Tributaria di merito gestita dal MEF-Dipartimento della Giustizia Tributaria, 
                    per la consultazione delle sentenze native digitali, pseudoanonimizzate, emesse dal 2021. Il servizio è liberamente accessibile e intende rappresentare 
                    un valido strumento di consultazione pubblica della giurisprudenza tributaria di merito per finalità di studio e ricerca. Il progetto è stato finanziato 
                    nell'ambito della risposta dell'Unione alla pandemia di Covid-19, partecipato dal Consiglio di Presidenza della Giustizia Tributaria e dal MEF.
                    <br/>Per accedere al servizio cliccare sul pulsante "<b><i>CONSULTA LA BANCA DATI</i></b> "
                  </span>
                </Col>
              </Row>
            </div>
            <div className="card-button-container">
              <div className="home-page-content mb-5 pb-5">
                <div>
                  <Row>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 pt-sm-4">
                      <a href="/ricerca">
                        <button type="button" className="btn btn-outline-primary custom-button">CONSULTA LA BANCA DATI<i className="bi bi-arrow-right ms-2"></i></button>
                      </a>
                    </div >
                    <div className={`col-lg-8 col-md-12 col-sm-12 col-xs-12 ${cards.length === 1 ? 'd-flex justify-content-end' : ''}`}>
                      <div className="row absolute-content">
                        {
                          cards.sort((a, b) => b.dataPubblicazione - a.dataPubblicazione).map((card, el) => {
                            const largeColumnLength = cards.length > 1 ? '6' : '12'
                            return (
                              <div key={`card-${el}`} id={`card-${el}`} className={`col-lg-${largeColumnLength} col-md-12 col-sm-12 col-xs-12 pt-sm-4 custom-padding-cards`}>
                                <InfoCard title={card.titoloHtml} content={card.testoHtml}></InfoCard>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div >
        : <Spinner />
  );
}

export default HomePage;

import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import Header from "./Header";
import './styles.css'
import { CONSTANTS } from "../utils/constants";

type SlimHeaderProps = {
  isLogged: boolean
  user: {
    name: string,
    familyName: string,
    fiscalCode: string,
    authmod: string,
    dateofbirth: string,
    adult: boolean
  }
  envData: {
    logoutUrl: string
  }
  isInHome: boolean;
}

const SlimHeader = (props: SlimHeaderProps) => {
  return (
    <header className={`${!props.isInHome ? "main-header" : "" }`} aria-label="Funzioni di servizio">
      <div className="header-top-dark">
        <div className="container py-2">
          <Row className="gx-variable align-items-center pl-1">
            <Col className="col-auto">
              {/* <Link to='https://www.mef.gov.it/index.html'> */}
              <Image
                className="logo-mef"
                src={process.env.PUBLIC_URL + "/img/logo_mef_negativo.svg"}
                alt="Logo del Mef"
              ></Image>
              {/* </Link> */}
            </Col>
            <Col className="margin-class">
              <a href="https://www.dgt.mef.gov.it" className="link-white fw-semibold">
                {CONSTANTS.ROOT_PAGE.headerSubTitle}
              </a>
            </Col>
          </Row>
        </div>
      </div>
      <Header isLogged={props.isLogged} user={props.user} envData={props.envData} />
    </header>
  );
}

export default SlimHeader;

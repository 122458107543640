import React from "react";
import './styles.css';
import { Link } from "react-router-dom";

type UserDropdownProps = {
    label: string,
    envData: {
        logoutUrl: string
    }
}

const UserDropdown = (props: any) => {

    const logoutUrl = props.envData.logoutUrl;

    const cleanup = () => {
        localStorage.removeItem('refresh')
    }

    return (
        <div className="dropdown">
            <button type="button" id="ex-dropdown" className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                <i className="bi bi-person-circle bi-lg me-2" />
                <span >{props.label}</span>
            </button>
            <ul className="dropdown-menu" aria-labelledby="ex-dropdown">
                <li>
                    <Link className="dropdown-item" to={`${logoutUrl}`} onClick={cleanup}>
                        <i className="bi bi-box-arrow-right bi-md me-2"></i>Esci e torna alla Home
                    </Link>
                </li>
            </ul>
        </div >
    );
}

export default UserDropdown;
import { createSlice } from "@reduxjs/toolkit";
import { fetchSearchDetails } from "./api";
import { SearchDetailsModel } from "./model";

interface SearchDetailsState {
  loading: boolean;
  data: SearchDetailsModel[];
}

const initialState: SearchDetailsState = {
  loading: false,
  data: [],
};

export const searchDetailsSlice = createSlice({
  name: "search details",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSearchDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSearchDetails.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchSearchDetails.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default searchDetailsSlice.reducer;

export const CONSTANTS = {
  ROOT_PAGE: {
    headerTitle: "Banca dati della Giurisprudenza Tributaria",
    headerSubTitle: "Dipartimento della Giustizia Tributaria",
  },
  HOME_PAGE: {
    title: "Home",
  },
  SEARCH_PAGE: {
    searchFormTitle: "Ricerca giurisprudenza",
    searchButtonLabel: "Ricerca",
    clearButtonLabel: "Ripulisci",
    emptySearchMessageFirstTime:
      "Avviare una ricerca per visualizzarne i risultati",
    emptySearchMessage: "Nessun risultato trovato",
    reportButtonLabel: "Segnala",
    downloadButtonLabel: "Scarica",
    showButtonLabel: "Visualizza",
    customTableTitle: "Risultati di ricerca",
    advancedResearchTitle: "Ricerca avanzata",
    researchSentenzeSegnalateTitle: "Ricerca",
    numberOfElementsPerPage: 10,
  },
  SEARCH_DETAILS_PAGE: {
    searchDetailsPathHomeLinkLabel: "Home",
    searchDetailsPathLinkLabel: "Ricerca giurisprudenza",
    searchDetailsPathListLinkLabel: "Elenco sentenze",
    searchSentenzeSegnalatePathLinkLabel: "Ricerca segnalazioni",
    searchSentenzeSegnalatePathListLinkLabel: "Elenco segnalazioni",
  },
};

export const DELAY = 30000
export const INVALID_DATE = 20220230

export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const TEXTAREA_WHITELIST_REGEX = /^[a-zA-Z0-9À-ÿ\s.\\\-_/:;,()'"%°€]*$/
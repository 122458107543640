import { createSlice } from "@reduxjs/toolkit";
import {
  fetchSearchResults,
  fetchSearchResultsAll,
  fetchSearchResultsControversia,
  fetchSearchResultsEsito,
  fetchSearchResultsMateria,
} from "./api";
import { PaginationDetails, SearchRequestModel, SearchResultsModel } from "./model";

interface SearchResultsState {
  loading: boolean;
  data: SearchResultsModel[];
  request: SearchRequestModel;
  valoreControversia: string;
  returnFromDetails: boolean;
  paginationDetails: PaginationDetails
}

const initialState: SearchResultsState = {
  loading: false,
  data: [],
  request: {
    filter: {
      campoRicerca: "",
      modRicerca: "T",
      modRicercaDescrizione: "Tutte le parole",
      numeroSentenza: "",
      annoSentenza: "",
      emissioneInizio: "",
      emissioneFine: "",
      gradoAutorita: "",
      autoritaEmittente: [],
      valoreControversiaMin: "",
      valoreControversiaMax: "",
      tipoGiudizio: "",
      esitoGiudizio: "",
      materia: "",
      classificazione: "",
      flagSpeseGiudizio: "",
      sentenzaImpugnata: ""
    },
    options: {
      enableSummary: false,
      pageSize: 0,
      pageNumber: 0
    }
  },
  valoreControversia: "",
  returnFromDetails: false,
  paginationDetails: {
    totalElements: 0,
    pageNumber: 0,
    totalPages: 0
  }
};

export const searchResultsSlice = createSlice({
  name: "search results",
  initialState,
  reducers: {
    setCampoRicerca(state, action) {
      state.request.filter.campoRicerca = action.payload;
    },
    setModRicerca(state, action) {
      state.request.filter.modRicerca = action.payload;
    },
    setModRicercaDescrizione(state, action) {
      state.request.filter.modRicercaDescrizione = action.payload;
    },
    setNumeroSentenza(state, action) {
      state.request.filter.numeroSentenza = action.payload;
    },
    setAnnoSentenza(state, action) {
      state.request.filter.annoSentenza = action.payload;
    },
    setEmissioneInizio(state, action) {
      state.request.filter.emissioneInizio = action.payload;
    },
    setEmissioneFine(state, action) {
      state.request.filter.emissioneFine = action.payload;
    },
    setGradoAutorita(state, action) {
      state.request.filter.gradoAutorita = action.payload;
    },
    setAutoritaEmittente(state, action) {
      state.request.filter.autoritaEmittente = action.payload;
    },
    setValoreControversia(state, action) {
      state.valoreControversia = action.payload;
    },
    setValoreControversiaMin(state, action) {
      state.request.filter.valoreControversiaMin = action.payload;
    },
    setValoreControversiaMax(state, action) {
      state.request.filter.valoreControversiaMax = action.payload;
    },
    setTipoGiudizio(state, action) {
      state.request.filter.tipoGiudizio = action.payload;
    },
    setEsitoGiudizio(state, action) {
      state.request.filter.esitoGiudizio = action.payload;
    },
    setSentenzaImpugnata(state, action) {
      if(action.payload !== null && action.payload !== undefined){
        state.request.filter.sentenzaImpugnata = action.payload;
      }
    },
    setMateria(state, action) {
      state.request.filter.materia = action.payload;
    },
    setClassificazione(state, action) {
      state.request.filter.classificazione = action.payload;
    },
    setSpeseGiudizio(state, action) {
      state.request.filter.flagSpeseGiudizio = action.payload;
    },
    setRequestAfterRefresh(state, action) {
      state.request = action.payload;
    },
    clearRequest(state, action) {
      state.request = initialState.request;
      state.valoreControversia = initialState.valoreControversia;
    },
    setReturnFromDetails(state, action) {
      state.returnFromDetails = action.payload;
    },
    setPaginationDetails(state, action) {
      state.paginationDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSearchResultsAll.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSearchResultsAll.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchSearchResultsAll.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchSearchResults.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSearchResults.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchSearchResults.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchSearchResultsControversia.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchSearchResultsControversia.fulfilled,
      (state, action) => {
        state.data = action.payload;
        state.loading = false;
      }
    );
    builder.addCase(fetchSearchResultsControversia.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchSearchResultsMateria.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSearchResultsMateria.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchSearchResultsMateria.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchSearchResultsEsito.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSearchResultsEsito.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchSearchResultsEsito.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  setCampoRicerca,
  setModRicerca,
  setModRicercaDescrizione,
  setNumeroSentenza,
  setAnnoSentenza,
  setEmissioneInizio,
  setEmissioneFine,
  setGradoAutorita,
  setAutoritaEmittente,
  setValoreControversiaMin,
  setValoreControversiaMax,
  setValoreControversia,
  setTipoGiudizio,
  setEsitoGiudizio,
  setMateria,
  setClassificazione,
  setSpeseGiudizio,
  clearRequest,
  setRequestAfterRefresh,
  setReturnFromDetails,
  setSentenzaImpugnata,
  setPaginationDetails
} = searchResultsSlice.actions;
export default searchResultsSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

interface ConfigState {
  envData: {
    logoutUrl: string;
  }
}

const initialState: ConfigState = {
  envData: {
    logoutUrl: ""
  }
};

export const configSlice = createSlice({
  name: "config results",
  initialState,
  reducers: {
    setConfig(state, action) {
      state.envData = action.payload;
    }
  }
});

export const {
  setConfig,
} = configSlice.actions;
export default configSlice.reducer;

import React, { useEffect } from "react";
import '../components/styles.css';
import { CONSTANTS } from "../utils/constants";

const FeedbackPage = () => {

    useEffect(() => {
        document.title = "Feedback - " + CONSTANTS.ROOT_PAGE.headerTitle;
    }, []);

    return (
        <div className="container py-3 mb-2 mt-4">
            <p>Work in progress...</p>
        </div >
    );
}

export default FeedbackPage;

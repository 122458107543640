import React, { useEffect } from "react";
import '../components/styles.css';
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CONSTANTS } from "../utils/constants";
import { clearRequest } from "../store/search/reducer";
import { useAppDispatch } from "../store/store";

type InfoPageProps = {
    title: string,
    content: string
}

const InfoPage = (props: InfoPageProps) => {

    const dispatch = useAppDispatch();

    useEffect(() => {
        setTimeout(() => {
            const topPage = document.getElementById('info-page')
            topPage?.scrollIntoView()
        }, 100);
    }, [props.title])

    useEffect(() => {
        document.title = props.title + " - " + CONSTANTS.ROOT_PAGE.headerTitle;

        try {
            if (window._paq) {
              window._paq.push(['setCustomUrl', String(window.location)]);
              window._paq.push(['setDocumentTitle', window.document.title]);
              window._paq.push(['setGenerationTimeMs', 0]);
              window._paq.push(['trackPageView']);
            }
          } catch (e) {
            console.error('trackPageView error', e);
          }
        }, []);

    return (
        <div className="container py-3 mb-2 mt-4" id="info-page">
             <div className="container py-3">
                <Row className="my-3">
                    <Col className="col-md-0">
                        <nav aria-label="Ti trovi in">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to='/' onClick={() => dispatch(clearRequest(""))}>{CONSTANTS.SEARCH_DETAILS_PAGE.searchDetailsPathHomeLinkLabel}</Link>
                                </li>
                                <li className="color-black breadcrumb-item active" aria-current="page">{props.title}</li>
                            </ol>
                        </nav>
                    </Col>
                </Row>
            </div>
            <h1>{props.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: props.content }} />
        </div >
    );
}

export default InfoPage;

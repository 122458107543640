import React, { useEffect, useState } from "react";
import "./App.css";
import { useLocation, useRoutes } from "react-router-dom";
import packageJson from '../package.json';
import Root from "./pages/Root";
import { useAppDispatch } from "./store/store";
import { clearRequest } from "./store/search/reducer";
import { setConfig } from "./store/config/reducer";
import { matomoScript } from './utils/matomoScript';

export interface ContextProps {
  logoutUrl: string
}


const App = () => {


  const [envData, setEnvData] = useState({
    logoutUrl: ""
  })
  const [isInHome, setIsInHome] = useState(true)

  const dispatch = useAppDispatch()
  const location = useLocation()

  useEffect(() => {
    console.log("APP VERSION: ", packageJson.version);
    const apiPath = process.env.REACT_APP_SERVER_HOST_PUBLIC;

    matomoScript();

    fetch(`${apiPath}/v1/config`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((res) => {
        if (res?.length > 0) {
          setEnvData({
            logoutUrl: res[0]?.value
          })
          dispatch(setConfig({
            logoutUrl: res[0]?.value
          }))
        }
      })
      .catch(() => {
        console.log("error")
      })
  }, [])



  const [isLogged, setIsLogged] = useState(false)
  const apiPathPrivate = process.env.REACT_APP_SERVER_HOST_PRIVATE;
  const [user, setUser] = useState<{
    name: string,
    familyName: string,
    fiscalCode: string,
    authmod: string,
    dateofbirth: string,
    adult: boolean
  }>({
    name: "",
    familyName: "",
    fiscalCode: "",
    authmod: "",
    dateofbirth: "",
    adult: false
  })

  useEffect(() => {
    checkLogin()
    if (location.pathname !== '/ricerca/dettaglio/segnala-sentenza') {
      localStorage.removeItem('state')
      localStorage.removeItem('refresh')
    }
    if (location.pathname !== '/') {
      setIsInHome(false)
    }
    else if (location.pathname === '/') {
      dispatch(clearRequest(""))
      localStorage.removeItem('request')
    }
  }, [location])

  const checkLogin = () => {
    fetch(`${apiPathPrivate}/user/info`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then((response: any) => {
        if ((response?.statusCode && response?.statusCode !== 200) || response?.error) {
          setIsLogged(false)
        }
        else {
          setIsLogged(true)
          setUser(response)
        }
      })
      .catch((error) => {
        setIsLogged(false)
      })
  }

  return (
    <div className="App">
      <Root isLogged={isLogged} user={user} envData={envData} isInHome={isInHome} />
    </div>
  );
}

export default App;

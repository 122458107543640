type ModalProps = {
    title: string,
    content: string,
    id: string
}
const Modal = (props: ModalProps) => {
    return (
        <div className="modal fade modal-lg" id={props.id} tabIndex={-1} aria-labelledby={`${props.id}-label`} data-bs-backdrop="static" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title card-title-custom" id={`${props.id}-label`}><b>{props.title}</b></h3>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Chiudi finestra di dialogo"></button>
                    </div>
                    <div className="modal-body card-text-custom" dangerouslySetInnerHTML={{ __html: props.content }} />
                    <div className="modal-footer justify-content-center">
                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Chiudi<span className="visually-hidden"> finestra di dialogo</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Modal;


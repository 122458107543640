import { createAsyncThunk } from "@reduxjs/toolkit";
import { SearchResultsModel } from "./model";

export const fetchSearchResultsAll = createAsyncThunk(
  "get/searchResultsAll",
  async (): Promise<[SearchResultsModel]> => {
    const response = await fetch(
      "https://interrogazione-web-be-prodigit-ocp2test.apps.ocp2v.cnt.sogei.it/v1/public/filter/all",
      {
        headers: new Headers({}),
        method: "GET",
      }
    );
    return await response.json();
  }
);

export const fetchSearchResults = createAsyncThunk(
  "get/searchResults",
  async (): Promise<[SearchResultsModel]> => {
    const response = await fetch(
      "https://interrogazione-web-be-prodigit-ocp2test.apps.ocp2v.cnt.sogei.it/v1/public/filter/autorita/all",
      {
        headers: new Headers({}),
        method: "GET",
      }
    );
    return await response.json();
  }
);

export const fetchSearchResultsControversia = createAsyncThunk(
  "get/searchResultsControversia",
  async (): Promise<[SearchResultsModel]> => {
    const response = await fetch(
      "https://interrogazione-web-be-prodigit-ocp2test.apps.ocp2v.cnt.sogei.it/v1/public/filter/controversia/all",
      {
        headers: new Headers({}),
        method: "GET",
      }
    );
    return await response.json();
  }
);

export const fetchSearchResultsMateria = createAsyncThunk(
  "get/searchResultsMateria",
  async (): Promise<[SearchResultsModel]> => {
    const response = await fetch(
      "https://interrogazione-web-be-prodigit-ocp2test.apps.ocp2v.cnt.sogei.it/v1/public/filter/materia/all",
      {
        headers: new Headers({}),
        method: "GET",
      }
    );
    return await response.json();
  }
);

export const fetchSearchResultsEsito = createAsyncThunk(
  "get/searchResultsEsito",
  async (): Promise<[SearchResultsModel]> => {
    const response = await fetch(
      "https://interrogazione-web-be-prodigit-ocp2test.apps.ocp2v.cnt.sogei.it/v1/public/filter/esito/all",
      {
        headers: new Headers({}),
        method: "GET",
      }
    );
    return await response.json();
  }
);